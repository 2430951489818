


.lang { 
  height: 20px;
  width: 20px;
  margin: 2px;
  /* margin-top: 7px; */
  transition: 0.6s;
  transform: scale(0.9);
  cursor: pointer;
}

.en {
  /* background:url(../images/en.png) center; */
  background-size:cover;
  margin-left: 5px;
}
.en_en {
  transform: scale(1.1);
}
.fr {
  /* background:url(../images/fr.png) center; */
  background-size:cover;
  margin-right: 5px;
}
.fr_fr {
  transform: scale(1.1);
}

.translator {
  height: 27px;
  width: 27px;
  border-radius: 100%;
  /* background:url(../images/translate.png) center; */
  background-size:cover;
  transform: scale(0.94);
}


.body-row{
  margin-top: 30px;
}


.excuse{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.377);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.excuse h2 {
  color: rgba(240, 248, 255, 0.623);
  text-align: center;
}

.excuse .the_a:hover{
  text-decoration: none;
  cursor: pointer;
}

.u-section-1 .u-sheet-1 {
  height: 500px;
}


.cool_bg{
  /* background:url(../images/retro_copy.jpg) center fixed; */
  background-size:cover;
}
.picture-1{
  /* background:url(../images/gettyimages-1049942668-612x612.jpg) center; */
  background-size:cover;
  border-radius: 6px;
}
.picture-2{
  /* background:url(../images/gettyimages-907837926-612x612.jpg) center; */
  background-size:cover;
  border-radius: 6px;
}
.h3{
  color: white;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
p{
  margin: 0px;
  padding:0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  color: white;
}
.p{
  color: white;
  font-size: 11px;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px;
  padding: 3px;
  font-family: sans-serif;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin: 0px;
}
.a{
  color: rgb(255, 255, 255);
  background-color: rgb(177, 6, 23);
  padding: 10px;
  margin-bottom: 0px;
  margin-top: 15px;
  border-radius: 3px;
}
.a_{
  cursor: pointer;
}
.a_:hover{
  text-decoration: none;
}
.a_:active{
  text-decoration: none;
}
.logo{
  /* background:url(../images/logo.jpg) center; */
  background-size:cover;
  /* border: solid 4px rgba(255, 255, 255, 0.1); */
}

.image1{
  /* background:url(../images/image1.jpg) center; */
  background-size:cover;
}
.image2{
  /* background:url(../images/image2.jpg) center; */
  background-size:cover;
}
.image3{
  /* background:url(../images/image3.jpg) center; */
  background-size:cover;
}
.image4{
  /* background:url(../images/image4.jpg) center; */
  background-size:cover;
}
.image5{
  /* background:url(../images/image5.jpg) center; */
  background-size:cover;
}

.animeTransition {
  transition: 0.7s;
}

.newPageCorrector {
  padding-right: 30px;
}



.extra_l{
  position: fixed;
  background-color: rgba(0,0,0,0.1);
  top: -100%;
  left: -100%;
  height: 300%;
  width: 300%;
}

.lightGlass{
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.darkGlass{
  position: absolute;
  background-color: rgba(0,0,0,0.2);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
}













/* NAVIGATION BAR */

.navigation-bar{
  height: 610px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.navigation-bar-iPhone{
  height: 610px;
  width: 98.8%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  transition: 0.6s;
}

.navigation-credits{
  position: absolute;
  bottom: 0px;
  font-family: sans-serif;
  font-size: 10px;
  text-align: center;
}

.navigation-credits2{
  position: absolute;
  bottom: 10px;
  font-family: sans-serif;
  font-size: 10px;
  display: none;
}

.navigation-credits.alt{
  position: relative;
  font-family: sans-serif;
  font-size: 12px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.logo{
  height: 120px;
  width: 120px;
  border-radius: 100%;
  margin-bottom: 10px;
  box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
}

.navigation-item{
  width: 90%;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  margin-top: 6px;
  padding: 4px;
  padding-left: 7px;
  box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: sans-serif;
  transition: 0.7s;
  font-size: 13px;
  font-weight: 500;
}

.navigation-item.active{
  background-color: rgba(177, 6, 23, 0.8);
  color: white;
  transform: scale(1.05);
  /* border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; */
}

.navigation-item:hover{
  transform: scale(0.95);
  text-decoration: none;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: rgb(255, 255, 255);
}

.navigation-item.important{
  background-color: rgba(177, 6, 23, 0.1);
}

.navigation-item:active{
  transform: scale(0.91);
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}


.navigation-item_{
  /* width: 90%; */
  background-color: rgba(0, 0, 0, 0.3);
  color: black;
  margin-top: 10px;
  padding: 4px;
  /* padding-left: 7px; */
  box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: sans-serif;
  transition: 0.7s;
  font-size: 13px;
  font-weight: 500;
  flex-direction: row;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* padding-left: 28%; */
  /* padding-top: 10px; */
}

.navigation-item_.important{
  background-color: rgba(177, 6, 23, 0.1);
}

.barCode{
  width: 30px;
  height: 30px;
  /* background:url(../images/replayOneIos.png) center; */
  background-size:cover;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.navigation-item_:hover{
  transform: scale(1.2);
  text-decoration: none;
  cursor: pointer;
}

.navigation-item_:active{
  transform: scale(1.1);
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}

/* END */




























/* CHILD BODY */

.child-body{
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
}

.picture-holder{
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}


.picture{
  height: 400px;
  width: 50%;
  justify-content: center;
  align-items: center;
}

.hoveringDiv{
  position: absolute;
  top: 0px;
  left: 0px;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 400px;
  width: 100%;
  transition: 0.6s;
}

.child{
  height: 250px;
  width: 330px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.8);
  z-index: 1;

  animation-delay:0s;
  animation-name: headShake;
  animation-duration:5s;
  animation-iteration-count: infinite;
  -webkit-animation-delay:0s;
  -webkit-animation-name: headShake;
  -webkit-animation-duration:5s;
  -webkit-animation-iteration-count: infinite;
}

.uline12{
  height: 2px;
  width: 80%;
  background-color: rgb(177, 6, 23); 
  /* rgb(179, 0, 12); */
  margin-top: 3px;
  margin-bottom: 3px;
}

.excuse_child{
  height: 100px;
  width: 10px;
  margin-left: 48%;
  align-self: center;
  position: relative;
  overflow: hidden;
}

.bubble{
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.058);
  overflow: hidden;

  animation-name: heartBeat;
  animation-duration:15s;
  animation-iteration-count: infinite;
  -webkit-animation-name: heartBeat;
  -webkit-animation-duration:15s;
  -webkit-animation-iteration-count: infinite;
}

.ba{
  bottom: 4%;
  left: 2%;
  height: 90px;
  width: 90px;

  animation-delay:0s;
  -webkit-animation-delay:0s;
}
.bd{
  top: 4%;
  left: 19%;

  animation-delay:4s;
  -webkit-animation-delay:4s;
}
.be{
  bottom: 5%;
  left: 40%;
  height: 20px;
  width: 20px;

  animation-delay:2s;
  -webkit-animation-delay:2s;
}
.bb{
  bottom: 5%;
  right: 13%;
  height: 60px;
  width: 60px;

  animation-delay:3s;
  -webkit-animation-delay:3s;
}
.bc{
  top: 6%;
  right: 3%;
  height: 100px;
  width: 100px;

  animation-delay:5s;
  -webkit-animation-delay:5s;
}

.sub-info{
  /* width: 100%; */
  /* height: 80px; */
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.sub-info-child{
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 3px;
  transition: 0.7s;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.sbInf-child-box{
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  transition: 0.7s;
  text-align: center;
}

.sbInf-child-box .fa{
  font-size: 23px;
}

.sbInf-child-box2{
  height: 98%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  transition: 0.7s;
  text-align: center;
  margin-top: 1%;
}

.sub-info-child:hover .sbInf-imageBg{
  transform: scale(1.4);
  border-radius: 6px;
}

.sub-info-child:hover .sbInf-child-text{
  opacity: 0;
  font-size: 0px;
}

.sub-info-child:hover .sbInf-child-box{
  transform: scale(1.2);
  border-radius: 6px;
}

.sub-info-child:hover .sbInf-darkGlass{
  padding: 15px;
}

.sbInf-imageBg{
  height: 96%;
  width: 96%;
  position: absolute;
  top: 2%;
  left: 2%;
  transition: 0.7s;
  z-index: -1;
  border-radius: 10px;
  background-color: rgb(255, 244, 248);
  box-shadow: 0px 5px 10px 4px rgba(0, 0, 0, 0.4);
}

.sbInf-imageGlass{
  height: 96%;
  width: 96%;
  position: absolute;
  top: 2%;
  left: 2%;
  transition: 0.7s;
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

.sbInf-darkGlass{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
  transition: 0.2s;
}

.sbInf-darkGlass2{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0,0,0,0.7);
  padding: 10px;
  transition: 0.2s;
  overflow: hidden;
  overflow-y: scroll;
}

.sbInf-darkGlass2::-webkit-scrollbar{
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
}

.sbInf-child-text{
  font-family: sans-serif;
  font-size: 11px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5px;
  transition: 0.7s;
}

.sbInf-child-p{
  font-family: sans-serif;
  font-size: 11px;
  color: rgba(255,255,255,0.5);
  transition: 0.7s;
}

.sbInf-child-p2{
  font-family: sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  transition: 0.7s;
  text-align: left;
  padding: 10px;
}

.sbInf-child-a{
  font-family: sans-serif;
  font-size: 11px;
  transition: 0.7s;
  text-decoration: none;
  width: 80%;
  height: 60%;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 10px;
  background-color: rgba(177, 6, 23, 0.623);
}

.sbInf-child-a:hover{
  font-family: sans-serif;
  font-size: 11px;
  transition: 0.7s;
  text-decoration: none;
  cursor: pointer;
}

.icn-a{
  font-size: 11px;
  padding-bottom: 2px;
  padding-right: 2px;
}

.icn-a-b{
  font-size: 11px;
  padding-bottom: 0px;
  padding-right: 2px;
}

.moreOptionsHolder{
  position: absolute;
  top: 10px;
  right: 25px;
  height: 30px;
  width: 100px;
  background-color: rgb(233, 231, 231);
  border-radius: 10px;
  display: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  box-shadow: 0px 0px 15px 20px rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.moreOptionsHolder p{
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 8px;
  padding-bottom: 0px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 34px;
}

.moreOptionsHolder_Icon{
  height: 30px;
  width: 30px;
  transition: 0.6s;
}

.andPlus{
  /* background:url(../images/andPlus.png) center no-repeat; */
  background-size: 20px;
}
.andMenu{
  /* background:url(../images/andMenu.png) center no-repeat; */
  background-size: 20px;
}

.moreOptionsHolder_Icon_rot{
  transform: rotate(45deg);
}

.moreOptionsHolder_Icon_rot2{
  transform: rotate(90deg);
}

.moreOptionsHolder_Icon:hover{
  transform: scale(1.3);
}

.moreOptionsHolder_Icon:active{
  transform: scale(0.75);
}


.moreOptionsHolder_Icon_rot:hover{
  transform: rotate(45deg) scale(1.3);
}

.moreOptionsHolder_Icon_rot:active{
  transform: rotate(45deg) scale(0.75);
}

.moreOptionsHolder_Icon_rot2:hover{
  transform: rotate(90deg) scale(1.3);
}

.moreOptionsHolder_Icon_rot2:active{
  transform: rotate(90deg) scale(0.75);
}

@media (max-width: 991px) {
  .child-body{
    overflow: hidden;
    overflow-y: scroll;
    box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .moreOptionsHolder{
    display: flex;
  }
  .navigation-bar-iPhone .navigation-item{
    width: 90%;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    margin-top: 6px;
    padding: 4px;
    /* padding-left: 7px; */
    box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-family: sans-serif;
    transition: 0.7s;
    font-size: 13px;
    font-weight: 500;
  }

  .navigation-bar-iPhone .navigation-item.active{
    background-color: rgba(177, 6, 23, 0.8);
    color: white;
    transform: scale(1);
  }
  
  .navigation-bar-iPhone .navigation-item:hover{
    transform: scale(0.95);
    text-decoration: none;
    cursor: pointer;
  }
  
  .navigation-bar-iPhone .navigation-item:active{
    transform: scale(1);
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s;
  }
}

/* END */





































/* EXTRA INFO */

.extra-info{
  height: 610px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  padding: 10px;
}

.hotline_container{
  width: 100%;
  height: 43%;
  box-shadow: -3px 4px 16px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  border-radius: 6px;
}

.hotline_Header{
  color: rgb(177, 6, 23);
  font-size: 20px;
  text-align: center;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-shadow: 0px 0px 22px rgba(0, 0, 0, 0.9);
}

.hotline_p{
  color: rgba(255, 255, 255, 1);
  font-size: 11px;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px;
  padding: 3px;
  font-family: sans-serif;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin: 0px;
  text-shadow: 0px 0px 12px rgba(0, 0, 0, 1);
  font-weight: bold;
}

.hotline_box{
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5px;
}

.hotline_hotline{
  height: 100%;
  width: 33%;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0.5%;
  cursor: pointer;
}

.hotline_hotline:hover{
  height: 100%;
  width: 33%;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0.5%;
  cursor: pointer;
  text-decoration: none;
}

.hotline_hotline_dp{
  height: 80px;
  width: 80px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.hotline_hotline_dp_image{
  font-size: 60px;
  color: rgba(0, 0, 0, 0.7);
}

.hotline_hotline_name_p{
  color: rgba(0,0,0,0.7);
  font-size: 9px;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px;
  padding: 3px;
  font-family: sans-serif;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin: 0px;
}

.hotline_hotline_name{
  color: rgba(0,0,0,1);
  font-size: 11px;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px;
  padding: 3px;
  font-family: sans-serif;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin: 0px;
}


.president_speech_container{
  width: 100%;
  height: 120px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 6px;
}

.president_speech_button{
  width: 100%;
  height: 50%;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 4px;
  cursor: pointer;
  transition: 0.6s;
}

.president_speech_DarkGlass{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  position: absolute;
  top: 0px;
  left: -50%;
  transition: 0.7s;
  z-index: -1;
}

.president_speech_a{
  font-size: 13px;
  color: rgba(255,255,255,0.6);
  transition: 0.4s;
  transform: scale(0.95);
  font-family: sans-serif;
}

.president_speech_a:hover{
  text-decoration: none;
  cursor: pointer;
}

.president_speech_button:hover .president_speech_DarkGlass{
  left: 0%;
}

.president_speech_button:hover .president_speech_a{
  transform: scale(1.1);
  color: white;
}


.contact_info_container{
  width: 100%;
  height: 35%;
  box-shadow: inset 0px 0px 56px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
  border-radius: 6px;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
}

.contact_info_link{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  width: 100%
}

.contact_info_link:hover{
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  cursor: pointer;
}

.contact_info_header{
  font-size: 10px;
}

  /* END */





































  /* MINISTERS NETWORK */

.mgb_h{
  color: white;
  margin-bottom: 2px;
  margin-top: 2px;
}

.mgb_line{
  width: 20%;
  margin-top: 0px;
}

.stdCards_holder{
  height: 100px;
  padding: 5px;
}

.stdCardsIMG_holder{
  width: 50px;
  height: 50px;
}

.stdCardsIMG{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid rgba(0,0,0,0.3);
  justify-content: center;
  align-items: center;
  display: flex;
}

.stdCards h6{
  font-size: 8px;
}


.mgbCards_holder{
  height: 300px;
  padding: 5px;
}

.mgbCards_holder.fct{
  height: 150px;
  padding: 5px;
}


.mgbCards_holder:hover{
  text-decoration: none;
  cursor: pointer;
}

.mgbCards{
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mgbCards.fct{
  height: 100%;
  width: 100%;
  background-color: rgba(215, 223, 243, 0.5);
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mgbCardsIMG_holder{
  width: 150px;
  height: 150px;
}

.mgbCardsIMG_holder.fct{
  width: 70px;
  height: 70px;
}

.stdCardsIMG_holder{
  width: 150px;
  height: 150px;
}

.mgbCardsIMG{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid rgba(0,0,0,0.3);
}

.mgbCardsIMG.fct{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 3px solid rgba(0,0,0,0.3);
}

.mgCardsIMG_holder {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* .qrCode { */
  /* position: absolute;
  bottom: 40px;
  right: 36px;
  overflow: hidden;
  border-radius: 5px;
  height: 20px;
  width: 20px; */
/* } */

.fctImag{
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 3px solid rgba(0,0,0,0.3);
}

.noBorder{
  border: 0px solid rgba(0,0,0,0);
}


.writeup_holder{
  color: black;
  text-align: center;
  /* height: 50%; */
}

.writeup_holder h6{
  color: rgba(0,0,0,0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.writeup_holder.fct h6{
  color: rgba(0,0,0,0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 12px;
}

.writeup_holder p{
  color: rgba(0,0,0,0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.writeup_holder.fct p{
  color: rgba(0,0,0,0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 10px;
}

.hide_{
  color: rgb(179, 0, 12);
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: smaller;
  cursor: pointer;
}
.hide_:hover{
  text-decoration: none;
}
.hide_:active{
  text-decoration: none;
}

.weblink{
  color: rgb(179, 0, 12);
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: smaller;
  cursor: pointer;
}
.weblink:hover{
  text-decoration: none;
  color: rgb(109, 4, 11);
}
.weblink:active{
  text-decoration: none;
  color: rgb(109, 4, 11);
}

.fcblink{
  color: rgb(19, 69, 145);
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: smaller;
  cursor: pointer;
}
.fcblink:hover{
  text-decoration: none;
  color: rgb(5, 49, 116);
}
.fcblink:active{
  text-decoration: none;
  color: rgb(5, 49, 116);
}

.fcbpage{
  color: rgb(17, 95, 2);
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: smaller;
  cursor: pointer;
}
.fcbpage:hover{
  text-decoration: none;
  color: rgb(10, 46, 3);
}
.fcbpage:active{
  text-decoration: none;
  color: rgb(10, 46, 3);
}

.mgb_info{
  font-size: smaller;
}

.s_hide{
  display: none;
}

.showInfo{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
}


.mgbCardsIMG_holder2{
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mgbCardsIMG2{
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 6px solid rgba(0,0,0,0.3);
}

.writeup_holder2{
  color: white;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.writeup_holder2 h3{
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.writeup_holder2 p{
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 6px;
  padding-bottom: 0px;
}


.mf_container{
  width: 100%;
}

.mf{
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  border-radius: 10px
}

@media (max-width: 991px) {
  .mgb_line{
    width: 40%;
    margin-top: 0px;
  }
  .mgbCards_holder{
    padding-right: 13px;
    height: 170px;
  }
  .mgbCards_holder.fct{
    height: 110px;
  }
  .mgbCardsIMG_holder.fct{
    width: 20%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }
  .mf_container{
    padding-right: 13px;
  }
  .stdCards_holder{
    height: 60px;
    padding: 5px;
  }
  .noBorder2{
    border: 0px;
    padding-bottom: 10px;
  }
  .stdCardsIMG_holder{
    width: 45px;
    height: 45px;
  }
  .stdCardsIMG{
    width: 45px;
    height: 45px;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.3);
  }
  .mgbCards{
    flex-direction: row;
  }
  .mgbCards.fct{
    flex-direction: row;
  }
  .stdCards{
    justify-content: flex-start;
    padding-left: 10px;
  }
  .stdCards h6{
    font-size: 15px;
    padding-left: 10px;
    padding-top: 0px;
    margin-top: 0px;
  }
  .mgbCardsIMG_holder{
    width: 30%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }
  .writeup_holder{
    color: black;
    text-align:left;
    width: 70%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .hide_{
    display: none;
  }
  .s_hide{
    display: flex;
  }
  .showInfo{
    flex-direction: column;
    align-items: center;
  }
  .writeup_holder2{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .noBorder{
    border: 0px solid rgba(0, 0, 0, 0);
  }
}

@media (max-width: 575px) {
  .mgbCards{
    flex-direction: row;
  }
  .mgbCardsIMG_holder{
    width: 40%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }
  .mgbCardsIMG_holder.fct{
    width: 20%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
  }
  .mgbCardsIMG{
    width: 120px;
    height: 120px;
  }
  .writeup_holder{
    color: black;
    text-align:left;
    width: 60%;
    height: 100%;
    justify-content: center;
    align-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
}


/* END */







































/* Online Classes */

.cls_child_body{
  padding-top: 20px;
}

.cls_search_holder{
  /* position: absolute;
  top: 20px;
  right: 0px; */
  height: 60px;
  width: 100%;
}

.cls_search_holder_1{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cls_search_holder_1 h4 {
  color: white;
}

.cls_search_holder_2{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.textInput{
  height: 40px;
  width: 70%;
  background-color: rgba(2, 2, 2, 0.466);
  border: 2px solid rgba(12, 12, 12, 0.966);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.searchButton {
  height: 40px;
  width: 15%;
  background-color: rgba(255, 253, 253, 0.747);
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.searchIcon{
  height: 30px;
  width: 30px;
}

.searchpng{
  /* background:url(../images/search.png) center no-repeat; */
  background-size: cover;
}

.clsCards_holder{
  height: 200px;
  padding: 3px;
}

.clsCards_holder:hover{
  text-decoration: none;
  cursor: pointer;
}

.clsCards{
  height: 100%;
  width: 100%;
  background-color: rgba(8, 8, 8, 0.9);
  border-radius: 10px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0px 0px 10px 5px rgba(255,255,255,0.1);
}

.clsCardsIMG_holder{
  width: 100%;
  height: 70%;
}

.clsCardsIMG{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 3px solid rgba(0,0,0,0.3);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clsCardsIMG_img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: center;
  background-size:cover;
  object-fit: cover;
}

.clsPlayIcon{
  position: absolute;
  /* bottom: 60px;
  right: 5px; */
  opacity: 0.7;
  transform: scale(0.7);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  transition: 0.6s;
}


.clswriteup_holder{
  color: white;
  /* height: 50%; */
}

.clswriteup_holder h6{
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.clswriteup_holder p{
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 10px;
}

.clshide_{
  color: rgb(179, 0, 12);
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  font-size: smaller;
  cursor: pointer;
}
.clshide_:hover{
  text-decoration: none;
}
.clshide_:active{
  text-decoration: none;
}


.cls_showInfo{
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(13px);
}

.cls_showInfo_{
  height: 90%;
  width: 90%;
  position: fixed;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  background-color: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 14px;
  backdrop-filter: blur(13px);
  overflow-y: scroll;
}

.clsCards2{
  height: 100%;
  /* background-color: rgba(8, 8, 8, 0.9); */
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.clsCardsIMG_holder2{
  width: 100%;
  /* height: 50%; */
  /* background-color: black; */
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clsCardsIMG_holder2_{
  display: none;
  width: 100%;
  height: 50%;
}

.clsCardsIMG2{
  width: 80%;
  height: 100%;
  border-radius: 10px;
  border: 3px solid rgba(0,0,0,0.3);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
}

.clsCardsIMG_img2{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: center;
  background-size:cover;
  object-fit: cover;
}

.clsPlayIcon2{
  position: absolute;
  /* bottom: 60px;
  right: 5px; */
  opacity: 0.7;
  transform: scale(1.7);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  transition: 0.6s;
}

.clswriteup_holder2{
  color: white;
  width: 98%;
  /* height: 50%; */
}

.clswriteup_holder2 h6{
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 20px;
}

.clswriteup_holder2 p{
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 10px;
}

.logIn_holder{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.log_in_box{
  width: 80%;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.178);
  padding: 5%;
}

.logIn_holder h4{
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
}

.logIn_holder p{
  color: rgba(255, 255, 255, 0.7);
  margin-top: 30px;
}

.log_in_box p{
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 4px;
  margin-top: 0px;
}

.log_in_box_holder {
  display: flex; 
  /* justify-content: center;
  align-items: center;  */
  /* background-color: rgba(0, 0, 0, 0.58); */
}

.log_in_box_text{
  padding-right: 5px;
  padding-top: 3px;
}

.radio_ {
  opacity: 0.7;
}

.log_in_box_input{
  width: 100%;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 15px;
  border-color: rgba(0, 0, 0, 0);
  padding-left: 10px;
  color: rgba(255, 255, 255, 0.6);
}

.real_login{
  background-color: rgba(0, 0, 0, 0.1);
  width: 90%;
  margin-left: 3%;
  margin-right: 3%;
  color: black;
}

.real_login_ p {
  margin-left: 3%;
  margin-right: 3%;
}

.real_login_ h4 {
  margin-left: 5%;
  margin-right: 3%;
  cursor: pointer;
}


.submit{
  width: 100%;
  text-align: center;
}
 .logIn{
   color: white;
   background-color: rgba(133, 7, 7, 0.747);
   padding: 10px;
   font-size: smaller;
   border-radius: 6px;
 }
.logIn:hover{
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.span{
  color: rgb(179, 0, 12);
}
.span:hover{
  color: rgb(179, 0, 12);
  text-decoration: none;
  cursor: pointer;
}


@media (max-width: 991px) {
  .cls_child_body{
    padding-top: 30px;
  }
  .cls_search_holder{
    top: 30px;
    background-color: rgba(0, 0, 0, 0.9);
    height: 90px;
    box-shadow: 0px 20px 20px 20px rgba(0, 0, 0, 0.9);
  }
  .cls_search_holder_1{
    justify-content: flex-end;
  }
  .textInput{
    width: 80%;
    background-color: rgba(255, 253, 253, 0.2);
    border: 2px solid rgba(255, 253, 253, 0.7);
  }
  .searchButton {
    width: 10%;
  }
  .searchIcon{
    height: 30px;
    width: 30px;
  }
  .clsCards_holder{
    height: 320px;
  }
  .clsPlayIcon{
    transform: scale(0.95);
  }
  .cls_showInfo {
    display: flex;
    flex-direction: column;
  }
  
  .clsCards3_ {
    display: none;
  }
  .clsCardsIMG_holder2_{
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 575px) {
  .textInput{
    width: 80%;
  }
  .searchButton {
    width: 10%;
  }
  .searchIcon{
    height: 20px;
    width: 20px;
  }
  .clsCards_holder{
    height: 200px;
    padding: 3px;
  }
  .clsPlayIcon{
    transform: scale(1.05);
  }
}




/* END */






































/* PORTALS */


.ptls{
  /* background: url(../images/imes335.jpeg) center; */
  overflow: hidden;
  overflow-y: hidden;
  background-size: cover;
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 15px;
}

.responsiveScroll {
  overflow-y: hidden;
  box-shadow: 3px 0px 5px 5px rgba(255, 253, 253, 0.1);
  /* overflow: hidden; */
}


.darkGlass_ptls{
  height: 200%;
  width: 100%;
  top: 0%;
  left: 0%;
  right: 0%;
  background-color: rgba(0, 0, 0, 0.637);
  position: absolute;
}

.regBox{
  background-color: rgba(0, 0, 0, 0.555);
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  backdrop-filter: blur(15px);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  /* left: -1px; */
}

.regBox_{
  height: 100%;
  /* left: -1px; */
  overflow-y: scroll;
  padding-bottom: 20px;
}

.formBlocker {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.formBlocker h6{
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
}

.formBlocker p{
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  text-transform: lowercase;
  text-align: center;
}

.regBox2{
  /* background-color: rgba(0, 0, 0, 0.555); */
  height: 100%;
  /* left: -1px; */
  overflow-y: scroll;
  padding-bottom: 20px;
}

.regBox2 p {
  font-size: smaller;
  margin-top: 6px;
  margin-bottom: 6px;
}

.regBox2 li {
  font-size: smaller;
  color: rgba(255, 255, 255, 0.712);
}

.regBox h4{
  text-align: center;
  color: rgba(255, 255, 255, 0.842);
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (max-width: 991px) {
  .responsiveScroll {
    overflow-y: scroll;
    box-shadow: 3px 0px 5px 5px rgba(255, 253, 253, 0.1);
    /* overflow: hidden; */
  }
  .regBox{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* left: -1px; */
  }
  .formBlocker{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* left: -1px; */
  }
}



/* END */










































.logInRegister {
  width: 100%;
  height: 40px;
  background-color: rgba(177, 6, 23, 0.905);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 4px;
}
.reg_a {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LGN__{
  display: flex;
}
.logInRegister p {
  margin-left: 5px;
  margin-right: 5px;
}
.LGN__-item{
  cursor: pointer;
  transition: 0.6s;
}
.LGN__-item:hover{
  text-decoration: none;
  transform: scale(1.05);
  color: antiquewhite;
}
.LGN__-item:active{
  text-decoration: none;
  transform: scale(0.95);
}

@media (max-width: 991px) {
  .logInRegister {
    height: 35px;
    margin-top: 6px;
  }
  .logInRegister p {
    font-size: 14px;
  }
  .LGN__-item{
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .logInRegister p {
    font-size: 10px;
  }
  .LGN__-item{
    font-size: 10px;
  }
  .logInRegister {
    height: 34px;
    margin-top: 7px;
  }
}

@media (max-width: 575px) {
  .logInRegister p {
    font-size: 9px;
  }
  .LGN__-item{
    font-size: 9px;
  }
  .logInRegister {
    height: 33px;
    margin-top: 8px;
  }
}














































/* FINISH ACCOUNT SETTINGS */

.finishAcountSt {
  overflow-y: hidden;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(255, 253, 253, 0.7);
  overflow-y: scroll;
}

.verifyEmail {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  /* border-radius: 5px; */
  margin-top: 10px;
}

.verifyEmail h4 {
  color: rgb(5, 1, 1);
  text-align: center;
}

.verifyEmail p {
  color: rgb(5, 1, 1);
  text-align: center;
}

.activateAccount {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  padding: 20px;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  /* border-radius: 5px; */
  margin-top: 10px;
}

.activateAccount h4 {
  color: rgb(5, 1, 1);
  text-align: center;
  margin-bottom: 0px;
}

.activateAccount p {
  color: rgb(5, 1, 1);
  text-align: center;
}

.plansHolder {
  width: 100%;
  transition: 0.6s;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: 0.6s;
}

.plans_ {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 16px 6px rgba(0, 0, 0, 0.2);
  background-color: rgb(233, 231, 230);
  /* background-color: rgb(221, 225, 255); */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.6s;
}

.plans_ p {
  transition: 0.6s;
}

.plans :hover {
  transform: scale(1.05);
}
.plans :active {
  transform: scale(0.96);
}


.fa_big {
  font-size: 40px;
  color: rgb(15, 4, 4);
  transition: 0.6s;
}

.privilages_holder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  transition: 0.6s;
}

.privilages {
  font-size: 11px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.fa_red {
  color: red;
}
.fa_green {
  color: green;
}

.checkOutDiv {
  
  height: 450px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.checkOutDiv p {
  color: rgb(15, 4, 4);
  margin-right: 10px;
}

.chckOtDv_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* overflow-x: hidden; */
  /* overflow-y: visible; */
}

.chckOtDv_ p {
  font-size: x-large;
  color: rgba(0, 0, 0, 0.5);
}

.chckOtDv_opps {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cool_ckot_div {
  height: 380px;
  width: 85%;
  box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1) ;
  margin-bottom: 20px;
  background-color: rgba(233,231,230,1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cool_ckot_div p {
  font-size: 40px;
  font-weight: bolder;
  opacity: 0.4;
}

.cool_ckot_div_{
  height: 380px;
  width: 85%;
  box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1) ;
  margin-bottom: 20px;
  background-color: rgba(233,231,230,1);
  border-radius: 4px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: flex-start;
  /* overflow: hidden; */
}

.chckOtDv_2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: 0.6s;
}

.chckOtDv_2:hover {
  transform: scale(1.05);
}

.chckOtDv_2_div {
  height: 380px;
  width: 90%;
  box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.1) ;
  margin-bottom: 20px;
  background-color: rgba(233,231,230,1);
  border-radius: 4px;
  overflow: hidden;
}

.chckOtDv_2_div h3{
  background-color: rgb(177, 6, 23);
  height: 50px;
  margin-top: 0px;
  color: rgba(233,231,230,1);
  padding-left: 6px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.chckOtDv_2_div p{
  margin-left: 4px;
  margin-right: 4px;
  color: rgba(2, 2, 2, 0.6);
  padding-bottom: 10px;
  padding-top: 10px;
}

.chckOtDv_2_div_p{
  margin-left: 4px;
  margin-right: 4px;
  color: rgba(2, 2, 2, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.checkOut_new_info {
  font-size: 12px;
  padding-left: 10px;
  padding-left: 10px;
}
.checkOut_new_{
  padding-left: 10px;
  padding-left: 10px;
  padding-bottom: 0px;
  margin-bottom: 5px;
}
.checkOut_new{
  font-size: 9px;
  padding-left: 10px;
  padding-left: 10px;
  padding-top: 0px;
  margin-top: 5px;
  background-color: rgb(177, 6, 23)!important;
  color: white !important;
}

.Vimeo__ {
  width: 300px;
  opacity: 0.4;
}

.chk_1 {
  font-size: 22px;
  padding-left: 10px;
}
.chk_2 {
  font-size: 13px;
  padding-left: 10px;
}
.chk_3 {
  font-size: 13px;
  padding-left: 10px;
}
.chk_4 {
  font-size: 10px;
  padding-left: 10px;
}
.chk_5 {
  font-size: 10px;
  padding-left: 10px;
}

@media (max-width: 991px) {

}


/* END  */






































/* GALLERY */


.glr_nav_bar {
  width: 98%;
  height: 40px;
  margin-top: 10px;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.28);
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 15px;
  z-index: 1;
}

.glr_nav {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 5px;
  border-right: 1px solid white;
  padding-right: 10px;
  opacity: 0.6;
  /* height: 30px; */
  transition: 0.6s;
  cursor: pointer;
  padding-left: 5px;
}

.glr_nav.active{
  opacity: 1;
  transform: scale(1.1);
}

.glr_nav:hover{
  /* opacity: 1; */
  transform: scale(1.2);
}

.glr_nav:active{
  /* opacity: 1; */
  transform: scale(0.9);
}

.gnEnd {
  border-right: 1px solid rgba(255, 255, 255, 0);
}

.GNIcon {
  color: rgb(0, 0, 0);
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.GNText {
  color: rgb(0, 0, 0);
  margin-left: 3px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.TabScreen{
  width: 102%;
  height: 100%;
  margin-bottom: 0.5%;
  /* background-color: rgba(255, 255, 255, 0.1); */
  transition: 0.6s;
  padding-top: 70px;
  padding-bottom: 0px;
  padding-left: 20px;
}

.albumHolder {
  display: flex;
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;
}

.an_album {
  width: 200px;
  height: 370px;
  margin-left: 10px;
}

.aAlum_card {
  width: 100%;
  height: 95%;
  border-radius: 25px;
  background-color: rgba(255,255,255,0.2);
  box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  transition: 0.6s;
  overflow: hidden;
}

.albm {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
}

.albumArt{
  width: 200px;
  height: 351.5px;
  object-fit: cover;
  border-radius: 25px;
  border: 0px solid rgba(0, 0, 0, 0);
}

.alnArt_alt {
  width: 200px;
  height: 351.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
}


.addAB {
  height: 100%;
  width: 50%;
}

.aAlum_card:hover {
  cursor: pointer;
  opacity: 0.8;
}

.aAlum_card:active {
  cursor: pointer;
  opacity: 0.8;
  transform: scale(0.8);
}

.aAlum_card i {
  font-size: 60px;
  opacity: 0.8;
}

.aAlum_info {
  height: 5%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.aAlum_info p {
  margin-top: 6px;
  color: white;
  font-size: 12px;
}

.AAI_amnt {
  padding-left: 6px;
}

.Default_albmHldr{
  width: 100%;
}

.indicator {
  font-weight: bold;
  color: white;
  font-size: 12px;
  margin-top: 20px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
}

.DAH_bar {
  /* width: 99%; */
  height: 140px;
  margin-top: 2px;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.58);
  background-color: rgba(255, 255, 255, 0.2);
  border: 15px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  transition: 0.6s;
  border-radius: 10px;
}

.DAH_bar:active {
  opacity: 0.8;
  border: 15px solid rgba(0, 0, 0, 0.1);
}

.DAH_bar:hover {
  border-width: 5px;
  /* transform: scale(1.1); */
}

.DAH_bar p {
  color: rgb(0, 0, 0);
}

.DAHB {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.DAHB p {
  color: rgb(0, 0, 0);
  padding-left: 0px;
  font-size: 10px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.DAHB i {
  color: white;
  font-size: 0px;
  text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.gthumbnail {
  height: 140px;
  width: 16%;
  margin: 0.3%;
  object-fit: cover;
  border-radius: 3px;
  border: 1px solid rgba(255,255,255,0.5) ;
  overflow-y: scroll;
  cursor: pointer;
  transition: 0.6s;
}

.gthumbnail:hover {
  transform: scale(1.1);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.5);
}

.loadingMedia_ {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LM_child {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LM_icon {
  font-size: 80px;
  transition: 0.6s;
}

.createAlbum {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CA_H h4 {
  color: white;
  font-size: 28px;
  font-weight: lighter;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.CA_H p {
  color: white;
  opacity: 0.8;
  font-size: 12px;
  margin-top: 15px;
  padding-bottom: 4px;
}

.CA_H input {
  border-radius: 5px;
  width: 500px;
  background-color: rgba(255,255,255,0.5);
  color: rgba(0, 0, 0, 0.795);
}

.CA_Hcancle {
  color: white;
  background-color: rgba(250, 0, 0, 0.3);
  margin-top: 10px;
  padding: 5px;
  padding-top: 3px;
  border-radius: 5px;
  transition: 0.6s;
}

.CA_Hcontnue {
  color: white;
  background-color: rgba(10, 36, 185, 0.3);
  margin-top: 10px;
  padding: 5px;
  padding-top: 3px;
  border-radius: 5px;
  margin-left: 7px;
  transition: 0.6s;
}

.CA_Hcancle:hover{
  text-decoration: none;
  color: white;
  cursor: pointer;
  opacity: 0.8;
}
.CA_Hcancle:active{
  text-decoration: none;
  color: white;
  opacity: 0.7;
}

.CA_Hcontnue:hover{
  text-decoration: none;
  color: white;
  cursor: pointer;
  opacity: 0.8;
}
.CA_Hcontnue:active{
  text-decoration: none;
  color: white;
  opacity: 0.7;
}

.backbutton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blanchedalmond;
  border-radius: 7px;
  padding: 5px;
  box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.5);
  transition: 0.6s;
  z-index: 1;
  cursor: pointer;
}

.backbutton:hover {
  opacity: 0.8;
}
.backbutton:active {
  transform: scale(0.8);
}

.backbutton i{
  color: black;
}


@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.LM_icon:hover {
  transform: scale(0.8);
}

.LM_p {
  font-size: 40px;
  transition: 0.6s;
  text-align: center;
}

.LM_p:hover {
  transform: scale(0.8);
}


@media (max-width: 991px) {
  .glr_nav_bar {
    width: 96%;
    height: 40px;
    margin-top: 10px;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.58);
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 10px;
    left: 2%;
  }
  .TabScreen{
    width: 102%;
    height: 100%;
    margin-bottom: 0.5%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: 0.6s;
    padding-top: 0px;
    padding-bottom: 100px;
  }
  .gthumbnail {
    height: 150px;
    width: 20%;
    margin: 0%;
    object-fit: cover;
    border-radius: 0px;
    border: 1px solid rgba(255,255,255,0.2) ;
  }
  .DAH_bar {
    width: 97.5%;
    height: 40px;
    margin-top: 2px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.58);
    background-color: rgba(255, 255, 255, 0.2);
    border: 0px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    transition: 0.4;
    flex-direction: row;
    border-radius: 0px;
  }
  .DAHB {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .DAHB p {
    color: rgb(0, 0, 0);
    padding-left: 5px;
    font-size: 15px;
  }
  .DAHB i {
    color: rgb(0, 0, 0);
    font-size: 15px;
  }
  .backbutton {
    position: absolute;
    bottom: 45px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blanchedalmond;
    border-radius: 7px;
    padding: 5px;
    box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.5);
    transition: 0.6s;
    z-index: 1;
    cursor: pointer;
  }
  .CA_H input {
    border-radius: 5px;
    width: 300px;
    background-color: rgba(255,255,255,0.5);
    color: rgba(0, 0, 0, 0.795);
  }
}

@media (max-width: 767px) {
  .gthumbnail {
    height: 150px;
    width: 25%;
    object-fit: cover;
    border-radius: 0px;
    border: 1px solid rgba(255,255,255,0.2) ;
  }
  .CA_H input {
    border-radius: 5px;
    width: 200px;
    background-color: rgba(255,255,255,0.5);
    color: rgba(0, 0, 0, 0.795);
  }
}

@media (max-width: 575px) {
  .gthumbnail {
    height: 140px;
    width: 33%;
    margin: 0%;
    object-fit: cover;
    border-radius: 0px;
    border: 1px solid rgba(255,255,255,0.2) ;
  }
}




/* END */
































.goog-te-banner-frame.skiptranslate {
  display: none !important;
  position: fixed !important;
  top: -100 !important;
  } 
  body {
    top: 0px !important; 
    }

body{
  /* background-color:rgb(207, 207, 207); */
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  background-color: rgb(207, 207, 207);
  color: rgb(207, 207, 207);
  width: 6px;
}
body::-webkit-scrollbar-track{
  background-color: rgb(207, 207, 207);
  width: 6px;
}
body::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: green;
}
body::-webkit-scrollbar-button{
  width: 6px;
}







@media (max-width: 1199px) {

  .u-section-1 .u-sheet-1 {
    min-height: 588px;
  }

  .u-section-1 .u-layout-wrap-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-1 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-1 .u-image-2 {
    height: 528px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-image-3 {
    min-height: 577px;
    background-position: 50% 50%;
  }

  .u-section-1 .u-group-1 {
    width: 323px;
    min-height: 461px;
    margin-bottom: 64px;
  }

  .u-section-1 .u-text-1 {
    position: relative;
  }

  .u-section-1 .u-line-1 {
    width: 82px;
    position: relative;
    transform: rotate(0.1034deg);
  }

  .u-section-1 .u-text-2 {
    position: relative;
  }

  .u-section-1 .u-btn-1 {
    position: relative;
  }
}

@media (max-width: 991px) {
  .body-row{
    margin-top: 0px;
  }

  .navigation-bar{
    display: none;
  }

  .navigation-bar-iPhone{
    display: flex;
  }

  .navigation-credits2{
    display: flex;
  }

  .hotline_container{
    opacity: 1;
  }

  .newPageCorrector {
    padding-right: 1.6%;
  }

  .extra-info{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.644);
    z-index: 1;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 30px;
    /* padding-left: 25px; */
    transition: 0.6s;
    width: 96%;
  }

  .regBox{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

}

@media (max-width: 767px) {

  .navigation-bar{
    display: none;
  }

  .navigation-bar-iPhone{
    display: flex ;
  }
  
  .navigation-credits2{
    display: flex;
  }
}

@media (max-width: 575px) {
  
  .navigation-credits2{
    display:flex;
  }

}


.google_translate_element{
  opacity: 0.5;
  border-radius: 10px;
}
















































/* LANDING PAGE  */

.landingPage {
  height: 400px;
  width: 100%;
  background-color: rgba(225, 225, 238, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lndP_box_WV{
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 25px 12px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  /* flex-direction: column; */
  padding: 0px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background-color: rgba(43, 43, 43, 0.9);
}

.WV_box {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  /* background-color: rgb(158, 69, 69); */
  height: 100%;
  overflow: scroll;
}

.WV_video_holder {
  width: 70.5%;
  height: 60.5%;
  background-color: rgba(136, 125, 125, 0.8);
  margin-top: 35px;
  border-radius: 9px;
  padding: 4px;
  overflow: hidden;
}
.WV_video_{
  width: 100%;
  height: 97%;
  background-color: black;
  border-radius: 9px;
  /* overflow: hidden; */
}
.video_vid{
  /* position: absolute; */
  height: 100%;
  width: 100%;
  background-color: black;
}
.WV_video_title h2{
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: rgb(255, 248, 248);
}
.WV_video_sub_title p {
  color: rgb(255, 248, 248);
  font-size: 12px;
  margin-top: 0px;
  padding-top: 0px;
}
.WV_controls{
  margin-top: 4px;
  margin-bottom: 4px;
  padding-left: 5px;
  padding-right: 5px;
}
.WV_controls_btn {
  width: 100%;
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.WV_controls_btn p{
  font-size: 12px;
  color: rgba(243, 243, 243, 0.774);
  /* margin-top: 8px; */
  margin-left: 10px;
}
.WV_player_btn{
  /* width: 0px;
  height: 60px; */
  /* border-radius: 100%; */
  /* background-color: rgb(174, 178, 182); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.WV_player_btn .fa{
  font-size: 55px;
  color: rgb(201, 198, 186);
  transition: 0.6s;
  cursor: pointer;
}
.WV_player_btn .fa:hover{
  transform: scale(1.09);
}
.WV_player_btn .fa:active{
  transform: scale(0.91);
}
.setVprogress{
  background-color: rgb(201, 198, 186);
  border-radius: 18px;
  padding: 4px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.6s;
  /* margin-top: 13px; */
}
.setVprogress:hover{
  transform: scale(1.09);
}
.setVprogress:active{
  transform: scale(0.91);
}
.setVprogress .fa{
  margin: 2px;
}
.setVprogress p {
  padding: 0px;
  margin: 0px;
  font-size: 7px;
  color: rgba(136, 125, 125, 1);
}

.progressHolder{
  width: 100%;
  height: 10px;
  background-color: rgba(240, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
}
.WV_progress{
  height: 100%;
  background-color: rgb(201, 198, 186, 0.2);
  border-radius: 20px;
  transition: 0.6s;
}



.WV_comnt_ {
  width: 100%;
}
.comt_input_holder{
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  align-items:center;
  background-color: rgba(136, 125, 125, 0.3);
  padding: 5px;
  margin-top: 10px;
  border-radius: 10px;;
}
.comt_input_ {
  width: 90%;
  /* height: 100px; */
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
  background-color: rgba(212, 202, 202, 0.356);
  border-radius: 10px;
  padding-top: 5px;
  padding-left: 3px;
}
.comt_input_button {
  width: 9%;
  height: 30px;
  margin-left: 1%;
  background-color: rgba(177, 6, 23, 0.905);
  color: white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 4px; */
}

.p_cmt_holder{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
  overflow: hidden;
  padding-top: 8px;
  padding-bottom: 12px;
  /* background-color: rgba(32, 13, 13, 0.466); */
  border-radius: 10px;
  box-shadow: 0px 0px 15px 6px rgba(255, 255, 255, 0.05) inset;
}
.PCDp_holder{
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PCDp_ {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.PCDp_ .fa {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.589);
}
.PCInfo_holder{
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.PCInfo_e{
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 5px;
  padding-bottom: 0px;
  color: rgba(248, 239, 239, 0.8);
}
.PCInfo_c{
  color: rgba(248, 239, 239, 0.6);
  font-size: 11px;
}
.p_CTime {
  position: absolute;
  /* bottom: 0px;
  right: 4px; */
  font-size: 8px;
}





.NextToPlay{
  width: 100%;
  margin-top: 35px;
}
.p_cmt_holder__{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 5px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  box-shadow: 0px 0px 15px 6px rgba(255, 255, 255, 0.05);
}
.PCDp_holder__{
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PCDp___ {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.PCDp___ .fa {
  font-size: 30px;
  color: rgba(255, 255, 255, 0.589);
}
.PCInfo_holder__{
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 5px;
}
.PCInfo_e__{
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  color: rgb(253, 245, 245);
}
.PCInfo_c__{
  color: rgb(253, 245, 245);
  font-size: 11px;
}



.lndP_box {
  height: 80%;
  width: 50%;
  box-shadow: 0px 0px 25px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.lndP_box h3{
  color: rgba(7, 2, 2, 0.608);
  text-align: center;
}
.lndP_box p {
  text-align: center;
  color: rgba(7, 2, 2, 0.508);
  font-size: 12px;
  margin-bottom: 60px;
}

.lndP_spiner{
  font-size: 30px;
  color: black;
}

.leavePage_links {
  display: flex;
}

.leavePage_l {
  font-size: 9px;
  margin-left: 6px;
  margin-right: 6px;
}

.green {
  color: blue;
}

@media (max-width: 991px) {
  .lndP_box {
    width: 70%;
  }
  .lndP_box_WV{
    height: 100%;
    width: 100%;
  }
  .PCInfo_c{
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .lndP_box {
    width: 80%;
  }
}


/* END */





































/* SETTINGS BOX */

.sett_box{
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
}
.settingsBox{
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}


.ST_btn {
  width: 60%;
  background-color: blue;
  margin-bottom: 10px;
  border-radius: 7px;
  padding: 4px;
  padding-left: 15px;
  cursor: pointer;
}
.sett_box input[type="file"]  {
  display: none;
}

.custom-file-upload {
  border: 10px solid rgba(204, 204, 204, 0.15);
  border-radius: 4px;
  /* display: inline-block; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  font-size: 12px;

}

/* .sett_box2{
  height: 100%;
  width: 100%;
  background-color: brown;
  position: absolute;
  top: 0;
  left: 0;
} */
.UP_progress {
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.014);
  margin-top: 10px;
  box-shadow: 0px 0px 5px 5px rgb(0, 0, 0, 0.1) inset;
  border-radius: 8px;
  overflow: hidden;
  transition: 0.6s;
  display: flex;
}
.progress_bar {
  height: 100%;
  transition: 0.6s;
  background-color: rgb(179, 0, 12);
  border-radius: 8px;
}

.UP_progress_{
  display: flex;
}

.UP_progress_ p {
  color: rgb(8, 2, 2);
  font-size: 11px;
  margin-right: 5px;
}