
/* ADIM SETTINGS */
.settings_nav {
  position: absolute;
  top: 0px;
  right: -50px;
  width: 60px;
  height: 200px;
  transition: 0.3s;
  background-color: green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 30px;
}

.settings_nav:hover {
  right: 0px;
}

.settings_indicator {
  position: absolute;
  height: 50px;
  width: 3px;
  left: 3px;
  background-color: aliceblue;
  border-radius: 2px;
  transition: 0.3s;
}

.settings_nav:hover .settings_indicator {
  height: 30px;
}

.settings_content {
  position: absolute;
  height: 100%;
  width: 45px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.settings_nav_icons {
  margin: 3px;
  transition: 0.6s;
  color: white;
}

.sni {
  font-size: 18px;
  width: 32px;
  height: 32px;
  display: flex;
  /* background-color: rgba(255,255,255,0.4);  */
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
}

.settings_nav_icons:hover {
  transform: scale(1.3) translate(-5px);
}

.settings_nav_icons:active {
  transform: scale(1.1);
}


.settings_ {
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  position: absolute;
  top: 0;
  left: 0;
  /* justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column; */
  transition: 0.3s;
  overflow-x: scroll;
  overflow-y: scroll;
}

.settingsHead{
  text-transform: capitalize;
  font-family:cursive;
  padding-top: 0px;
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  color: black;
}

.elements {
  color: black;
}

.settings_edit {
  background-color: aliceblue;
  height: 200px;
  width: 200px;
  position: absolute;
  transition: 0.6s;
  /* left: -100px; */
  display: flex;
  z-index: 300;
}


.psmall {
  font-size:x-small;
  font-family: sans-serif;
}

.hunnidprcnt {
  width: 100%;
}