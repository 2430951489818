/*///////REACT'S OWN CSS////////*/

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  transition: 5s;
  /* pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #1f1c1c00;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header_ {
  background-color: #1f1c1c00;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/*///////REACT'S OWN CSS END////////*/






.lightText {
  color: rgb(228, 210, 210);
}

.darkText {
  color: rgb(29, 24, 24);
}



.umbrellaLogo_text {
  font-weight: 100;
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.umbrellaLogo_p {
  font-weight: 100;
  font-size: 12px;
  margin-top: 0px;
}









.App_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 100%;
  width: 100%;
}

.App__ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 90vh;
  width: 55vh;
  /* background-color: rgba(165, 11, 132, 0); */
  background: url(../public/assets/img/rainEffect.png) center;
  background-size: cover;
  border-radius: 30px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.432);
}


.section {
  width: 96%;
  margin-left: 4%;
}

.animate_repeat_infinit {
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-delay: 2s;
  -o-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -webkit-animation-delay: 2s;
}



























/* StartupView */


.StartupVideo {
  /* width: 50%;
  height: 50%; */
}

.startUp_info {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0%;
  right: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.glass {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: rgba(240, 248, 255, 0.45);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: -1;
  transition: 0.5s ease-out;
}

.startUp_info img {
  transition: 0.7s cubic-bezier(.47, 1.64, .41, .8);
  position: relative;
}

.startUp_info .link_toStartUp {
  transition: 0.5s cubic-bezier(.47, 1.64, .41, .8);
  position: absolute;
  overflow: hidden;
  padding: 15px;
  background-color: rgb(190, 10, 25);
  color: aliceblue;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.6s;
  cursor: pointer;
}

.link_tST {
  transform: scale(0);
}

.startUp_info .link_toStartUp_view {
  display: flex;
  transition: 0.5s cubic-bezier(.47, 1.64, .41, .8);
  position: relative;
  width: 200px;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.startUp_info a {
  overflow: hidden;
  padding: 15px;
  background-color: rgb(190, 10, 25);
  color: aliceblue;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.6s;
  cursor: pointer;
}

.startUp_info a:hover {
  background-color: rgb(82, 3, 10);
  text-decoration: none;
  color: aliceblue;
}

.startUp_info .fa-arrow-right {
  /* transition: 10s; */
  color: aliceblue;
  animation-name: rubberBand;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  padding-left: 5px;
}

.startUp_info .countDown {
  position: absolute;
  bottom: 5%;
  right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.startUp_info .countDown_show {
  transition: 0.3s;
  opacity: 1;
}

.startUp_info .countDown_hide {
  transition: 0.7s;
  opacity: 0.1;
}

.startUp_info .countDown p {
  color: aliceblue;
  font-size: 30px;
}

.startUp_info .countDown h1 {
  color: aliceblue;
  font-size: 70px;
}




/* end of StartupView */



























/* AdsSystem */

.AdsSystem {
  height: 120px;
  width: 120px;
  background-color: rgb(11, 77, 209);
  position: fixed;
  bottom: 20px;
  border-radius: 20px;
  transition: 0.6s cubic-bezier(.47, 1.64, .41, .8);
  box-shadow: 0px 0px 14px 4px rgba(0, 0, 0, 0.436);
}

.AdsSystem_close {
  height: 30px;
  width: 30px;
  background-color: rgb(11, 77, 209);
  position: fixed;
  bottom: 120px;
  border-radius: 20px;
  transition: 0.6s cubic-bezier(.47, 1.64, .41, .8);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.536);
  display: flex;
  justify-content: center;
  align-items: center;
}


.AdsSystem_Active {
  right: 20px;
}

.AdsSystem_Active__ {
  height: 100px;
  width: 270px;
  background-color: rgb(21, 47, 99);
}

.AdsSystem_inActive {
  right: -230px;
}


.AdsSystem_close i {
  color: aliceblue;
  font-weight: 100;
}

.AdsSystem_Active_close {
  right: 15px;
}

.AdsSystem_Active___close {
  bottom: 105px;
  background-color: rgb(21, 47, 99);
}

.AdsSystem_inActive_close {
  right: -230px;
}

.AbrvInfo {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
}

.AbrvInfo img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.AbrvInfo p {
  font-size: 10px;
  margin-top: 6px;
  font-family: Verdana;
}


.fullInfo {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
}

.fullInfo img {
  width: 40%;
  height: 100%;
  object-fit: cover;
  /* margin-left: 2%; */
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 5px solid rgb(21, 47, 99);
}

.fullInfo div {
  padding: 5px;
  width: 60%;
}

.fullInfo p {
  font-size: 10px;
  /* margin-top: 6px; */
  font-family: Verdana;
  font-weight: 100;
}

.fullInfo .title {
  font-size: 10px;
  /* margin-top: 6px; */
  font-family: Verdana;
  font-weight: bold;
}


/* End of AdsSystem */





/* newsletterSystem */


.showWriteEmail {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.559);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 4;
  transition: 0.6s;
  opacity: 0;
}

.showWriteEmail_box {
  width: 40%;
  /* height: 40%; */
  background-color: rgb(255, 250, 244);
  border-radius: 20px;
  background: url(../src/assets/img/orange-mail-omgzdepm3h4odrclrfvyid9zs0v5cqn0s4jsi2ws34.png) center;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.6s cubic-bezier(.47, 1.64, .41, .8);
  transform: scale(0);
}

.showWriteEmail_box input {
  background-color: rgba(0, 0, 0, 0.13);
  color: rgb(6, 6, 6);
}

.showWriteEmail_anim {
  opacity: 1;
}

.showWriteEmail_box_anim {
  transform: scale(1);
}

.showWriteEmail_box_ {
  height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 20px;
  /* background-color: rgba(255, 228, 196, 0.648); */
}

.showWriteEmail_sending {
  font-size: small;
  font-weight: 200;
  font-family: Verdana;
  margin-bottom: 10px;
  color: black !important;
}

.showWriteEmail_error {
  font-size: small;
  font-weight: 200;
  font-family: Verdana;
  margin-bottom: 10px;
  color: rgb(255, 0, 0) !important;
}

.showWriteEmail_success {
  font-size: small;
  font-weight: 200;
  font-family: Verdana;
  margin-bottom: 10px;
  color: rgb(34, 158, 0) !important;
}

.showWriteEmail p {
  font-size: small;
  font-weight: 200;
  font-family: Verdana;
  margin-bottom: 10px;
  color: black;
}

.showWriteEmail .title {
  font-size: large;
  font-weight: 200;
  font-family: Verdana;
  margin-bottom: 10px;
  color: black;
}

.showWriteEmail .subscribe {
  font-size: small;
  background-color: blue;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  color: rgb(244, 241, 236);
}

.showWriteEmail .cancel {
  font-size: small;
  background-color: rgb(164, 25, 0);
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(248, 244, 240);
  width: 30px;
  height: 30px;
  border-radius: 20px;
  position: absolute;
  top: 2%;
  right: 2%;
}


@media (max-width: 991px) {
  .showWriteEmail_box {
    width: 80%;
    background-color: rgba(250, 235, 215, 0.43);
    border-radius: 20px;
    padding: 30px;
  }
}





/* End of newsletterSystem */





















/* ELEMENTS */

.elements p {
  text-align: left !important;
}

.elements .metaDataDiv {
  width: 100%;
}

.elements .metaDataDiv a {
  border: 0px solid rgba(0, 0, 0, 0) !important;
  font-size: 1.3rem !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.elements .metaDataDiv i {
  border: 0px solid rgba(0, 0, 0, 0) !important;
  font-size: 0.5rem !important;
  margin-left: 4px !important;
  margin-right: 6px !important;
}

.elements .details {
  font-weight: bold;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 2rem;
  cursor: pointer;
}

.element_p {
  padding-left: 0px !important;
  padding-right: 0px !important;
  letter-spacing: 1px !important;
  padding-top: 1.1rem;
}

.element_coverImage_div {
  cursor: pointer;
  overflow: hidden;
}

.element_coverImage_image {
  transition: 0.6s;
}

.element_coverImage_image_glass {
  position: relative;
  top: -100%;
  left: 0px;
  background-color: rgba(132, 15, 15, 0.427);
  opacity: 0;
  transition: 0.6s;
}

.defaultPreset:hover .element_coverImage_image {
  transform: scale(1.1);
}

.defaultPreset:hover .element_coverImage_image_glass {
  opacity: 0.5;
}

.dateTag {
  top: 4%;
  right: 3%;
  position: absolute;
  height: 80px;
  width: 42px;
  background-color: white;
}




.shareMediaDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

.shareMediaDiv img {
  height: 20px;
  width: 20px;
  margin-right: 0px;
  padding-right: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.shareMediaDiv p {
  margin-right: 0px !important;
  padding-right: 0px !important;
  margin-left: 5px !important;
  padding-left: 0px !important;
  color: aliceblue !important;
}

.shareMediaDiv div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px;
  transition: 0.4s;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.367);
}

.shareMediaDiv div:hover {
  transform: scale(1.1) translateY(-10px);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.367);
}

.shareMediaDiv div:active {
  transform: scale(0.98) translateY(0px);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.367);
}

.shareMediaDiv .Facebook {
  background-color: rgb(30, 30, 145);
}

.shareMediaDiv .Twitter {
  background-color: rgb(3, 126, 220);
}

.shareMediaDiv .Google {
  background-color: rgb(255, 68, 0);
}

.shareMediaDiv .Pinterest {
  background-color: rgb(197, 16, 16);
}

.shareMediaDiv .LinkedIn {
  background-color: rgb(6, 124, 174);
}






/* end of ELEMENTS */



























/* .HeaderAnimComp */

.coolTravelHeader {
  height: 50vw;
  width: 100vw;
  display: flex;
  overflow: hidden;
  position: relative;
}

.coolTravelHeader_linearGradien {
  width: 105vw;
  height: 10vw;
  position: absolute;
  left: 0px;
  /* bottom: 0px; */
  background-image: linear-gradient(to top, #00000000, rgba(255, 255, 255, 0.598), white);
}

.CTH_col {
  height: 50vw;
  width: 50vw;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* background-color: #550f62; */
}

.CTH_col1 {
  width: 60vw;
}

.CTH_col2 {
  width: 40vw;
}

.CTH_col_img {
  height: 100%;
  width: 65vw;
  object-fit: cover;
}


.circleButton {
  /* background-color: white; */
  height: 10vw;
  width: 10vw;
  position: absolute;
  left: 5vw;
  top: 35vw;
  border-radius: 10vw;
  /* box-shadow: 0px 0px 2vw 0px rgba(0,0,0,0.3); */
}

.circleButton2 {
  /* background-color: white; */
  height: 5vw;
  width: 5vw;
  position: absolute;
  left: 1.8vw;
  top: 56vw;
  border-radius: 10vw;
  /* box-shadow: 0px 0px 2vw 0px rgba(0,0,0,0.3); */
}

.circleButton3 {
  /* background-color: white; */
  height: 5vw;
  width: 5vw;
  position: absolute;
  right: 1.8vw;
  top: 46vw;
  border-radius: 10vw;
  /* box-shadow: 0px 0px 2vw 0px rgba(0,0,0,0.3); */
}

.circleButton4 {
  /* background-color: white; */
  height: 10vw;
  width: 10vw;
  position: absolute;
  right: 3vw;
  top: 62vw;
  border-radius: 10vw;
  /* box-shadow: 0px 0px 2vw 0px rgba(0,0,0,0.3); */
}

.devLine1 {
  width: 2px;
  height: 10vw;
  background-color: green;
  position: absolute;
  top: 0.5vw;
  left: 10vw;
  transform: rotate(-50deg);
}

.devLine2 {
  width: 2px;
  height: 7vw;
  background-color: green;
  position: absolute;
  top: 6.8vw;
  left: 11vw;
  transform: rotate(-80deg);
}


.devLine3 {
  width: 2px;
  height: 9vw;
  background-color: green;
  position: absolute;
  top: 2vw;
  right: 6vw;
  transform: rotate(14deg);
}

.devLine4 {
  width: 2px;
  height: 5vw;
  background-color: green;
  position: absolute;
  top: 8.8vw;
  right: 14vw;
  transform: rotate(-50deg);
}


.warp1 {
  height: 60vw;
  width: 70vw;
  background-color: rgb(233, 145, 2);
  background-image: linear-gradient(to bottom, rgb(233, 145, 2), rgb(4, 77, 4));
  border-radius: 15vw;
  position: absolute;
  transform: rotate(-25deg);
  top: -35vw;
  left: -20vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.warp2 {
  height: 60vw;
  width: 70vw;
  background-color: #0f621a;
  background-image: linear-gradient(to top, rgb(233, 145, 2), rgb(4, 77, 4));
  border-radius: 15vw;
  position: absolute;
  transform: rotate(-25deg);
  top: 20vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.WarpImg {
  height: 60vw;
  width: 70vw;
  transition: 1.4s;
  transform: rotate(25deg);
  position: absolute;
  opacity: 0.1;
}

.WarpTop {
  top: 25vw;
  left: -20vw;
}

.WarpBottom {
  top: -33.3vw;
  left: -14.9vw;
}

.WarpImg1 {
  background: url(./assets/img/kingdomlivingwebsitecontent/airportSample4.jpg) center fixed no-repeat;
  background-size: cover;
}

.WarpImg2 {
  background: url(./assets/img/kingdomlivingwebsitecontent/cape3.jpg) center fixed no-repeat;
  background-size: cover;
}

.WarpImg3 {
  background: url(./assets/img/kingdomlivingwebsitecontent/16256-113963-f70729568_3xl.jpg) center fixed no-repeat;
  background-size: cover;
}

.WarpImg4 {
  background: url(./assets/img/kingdomlivingwebsitecontent/16256-113963-f64655723_3xl.jpg) center fixed no-repeat;
  background-size: cover;
}

/* .WarpImg5{
  background: url(./assets/img/kingdomlivingwebsitecontent/cape1.jpg) center fixed no-repeat;
  background-size: cover;
}
.WarpImg6{
  background: url(./assets/img/kingdomlivingwebsitecontent/cape5.jpg) center fixed no-repeat;
  background-size: cover;
}
.WarpImg7{
  background: url(./assets/img/kingdomlivingwebsitecontent/cape6.jpg) center fixed no-repeat;
  background-size: cover;
}
.WarpImg8{
  background: url(./assets/img/kingdomlivingwebsitecontent/airportSample1.jpg) center fixed no-repeat;
  background-size: cover;
} */

.WarpImgText {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 1.4s;
  transition-delay: 0.6s;
}

.WarpImgTitle {
  color: white;
  font-size: 6vw;
  transform: rotate(-25deg);
  transition: 1.4s;
  position: absolute;
  top: 8vw;
  left: -5vw;
  width: 100%;
  opacity: 0.2;
  font-weight: bolder !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  z-index: 0;
}

.WarpImgInfo {
  color: white;
  font-size: 1.5vw;
  transform: rotate(-24.5deg);
  transition: 1.4s;
  position: absolute;
  top: 35vw;
  left: 5vw;
  width: 80%;
  opacity: 0.2;
  font-weight: bolder !important;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  z-index: 0;
}



.airplane {
  height: 15vw;
  width: 15vw;
  position: absolute;
  top: 22vw;
  left: 1.6vw;
  object-fit: contain !important;
  transform: rotate(10deg);
  transition: 0.6s;
  text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.airplane:hover {
  transform: scale(1.08) rotate(10deg);
}

.darkOverlay {
  height: 100%;
  width: 65vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.163);
  top: 0px;
}

.africa_ {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 50vw;
  width: 60vw;
  object-fit: contain !important;
  transform: rotate(-15deg);
  opacity: 0.4;
}







.CoolTravel_OurServices {
  position: relative;
}

.CT_services {
  background-color: #3b2803;
  /* height: 40vw; */
  width: 100vw;
  position: relative;
}

.CT_services_intro {
  width: 100vw;
  position: absolute;
  top: -30vw;
  height: 30vw;
  /* background-color: #3b280386; */
  background-image: linear-gradient(to top, #3b2803, rgba(4, 77, 4, 0));
  display: flex;
  align-items: flex-end;
  padding-left: 4vw;
  padding-bottom: 2vw;
}








.homeHiglight {
  width: 100vw;
  height: 20vw;
  /* background-color: #550f62; */
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding-right: 2vw;
  position: relative;
}

.homeHiglight_child {
  background-color: #fffcfe;
  height: 17vw;
  width: 15vw;
  margin: 1vw;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  position: relative;
  overflow: hidden;
}

.homeHiglight_child h4 {
  font-weight: 500;
  font-family: 'Gill Sans';
  text-align: center;
  font-size: 1.7vw;
  color: rgb(13, 197, 13);
  z-index: 1;
}

.homeHiglight_child div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.7);
}

.homeHiglight_child1 {
  background: url(./assets/img/kingdomlivingwebsitecontent/Woman-Relaxing-At-The-Beach-On-A-Beach-Lounge-Beach-Vacation-Concept.jpg) center no-repeat;
  background-size: cover;
}

.homeHiglight_child2 {
  background: url(./assets/img/kingdomlivingwebsitecontent/tourist-photographer-pointing-blue-sky-standing-front-pyramids-las-vegas-asian-woman-smiling-hat-sunglasses-summer-holiday-travel-usa_678158-6391.jpg) center no-repeat;
  background-size: cover;
}

.homeHiglight_child3 {
  background: url(./assets/img/kingdomlivingwebsitecontent/630x355.jpg) center no-repeat;
  background-size: cover;
}

.homeHiglight_child4 {
  background: url(./assets/img/kingdomlivingwebsitecontent/1682607884698.png) center no-repeat;
  background-size: cover;
}

.HHC_icon {
  width: 8vw;
  height: 8vw;
  z-index: 1;
  /* filter: #550f62; */
  /* -webkit-filter: grayscale(100%);  safari/chrome */
  /*  -moz-filter: grayscale(100%); /* firefox */
  /*   -ms-filter: grayscale(100%);  /* IE >= 9 */
  /*   -o-filter: grayscale(100%); /* Opera, if support CSS3 */
  /*   filter: gray; filter: grayscale(100%); /* edge browsers */
}


.home_intro {
  width: 100vw;
  background-color: #282c34;
  background: url(./assets/img/kingdomlivingwebsitecontent/map3.jpg) center no-repeat;
  background-size: cover;
}

.home_intro_ {
  padding: 4vw;
  /* background-color: rgba(85, 15, 98, 0.501); */
  background-color: rgba(3, 27, 59, 0.729);
}

.home_intro_ h1 {
  color: white;
  font-family: edo;
  font-size: 4vw;
  margin: 0;
  text-align: center;
}

.home_intro_ h2 {
  color: white;
  font-family: Silent_Asia;
  font-size: 3.5vw;
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
}

.home_intro_ p {
  color: white;
  font-family: 'Gill Sans';
  /* font-size: 1.4vw; */
}


.Home_linearGradien {
  width: 105vw;
  height: 10vw;
  /* position: absolute;
  left: 0px;
  bottom: 0px; */
  background-image: linear-gradient(to bottom, #031b3bba, white);
}


/* Sketch
Admiration_Pains
edo
The_Bully
Silent_Asia */



/* END */






































/* .HeaderAnimComp_OBSOLATE SECTION */



.HeaderAnimComp {
  width: 100%;
  overflow: hidden;
}

.Bubbles_holder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.4s;
  /* overflow: hidden; */
}

.Bubble {
  background-color: #282c34;
  position: relative;
  /* top: -50%;
  left: -30%; */
  transition: 1.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Bubble_info {
  /* width: 100%;
  height: 100%; */
  background-color: rgba(0, 0, 0, 0.536);
  transition: 1.3s;
  position: absolute;
}

/* .Bubble_info_dialogue{
  position: absolute;
  bottom: 50px;
  right: 60px;
  width: 40%;
} */
.Bubble_info_h1 {
  color: aliceblue;
  margin-top: 0px;
  font-size: 7vw;
}

.Bubble_info_h2 {
  color: aliceblue;
  margin-top: 0px;
  font-size: 2.35vw;
}

.Bubble_info_p {
  color: aliceblue;
  margin-top: 0px;
  font-size: 1.35vw;
}

.Bubble_info_a {
  color: white;
  background-color: #0f621a;
  padding: 5px;
  top: 10px;
  font-size: 1.35vw;
  position: relative;
}

.Bubble_info_a:hover {
  color: white;
  background-color: #04ac1b;
}

@media (max-width: 991px) {
  .Bubble_info_h1 {
    font-size: 9vw;
  }

  .Bubble_info_h2 {
    font-size: 3.35vw;
  }

  .Bubble_info_p {
    font-size: 2.35vw;
  }

  .Bubble_info_a {
    font-size: 2.35vw;
  }
}

/* @media (max-width: 991px) {
  .Bubble_info_h1 {
    font-size: small;
  }
  .Bubble_info_h2{
    font-size: small;
  }
  .Bubble_info_p{
    font-size: small;
  }
}

@media (max-width: 785px) {
  .Bubble_info_h1 {
    font-size: smaller;
  }
  .Bubble_info_h2{
    font-size: smaller;
  }
  .Bubble_info_p{
    font-size: smaller;
  }
}

@media (max-width: 571px) {
  .Bubble_info_h1 {
    font-size: x-small;
  }
  .Bubble_info_h2{
    font-size: x-small;
  }
  .Bubble_info_p{
    font-size: x-small;
  }
}

@media (max-width: 498px) {
  .Bubble_info_h1 {
    font-size: xx-small;
  }
  .Bubble_info_h2{
    font-size: xx-small;
  }
  .Bubble_info_p{
    font-size: xx-small;
  }
} */



.Bubble1 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/airportSample4.jpg) center no-repeat;
  background-size: cover;
}

.Bubble2 .Bubble_img {
  background: url(../src/assets/img/53837180_564801474005464_4302007073815658496_n.jpg) center no-repeat;
  background-size: cover;
}

.Bubble3 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/cape4.jpg) center no-repeat;
  background-size: cover;
}

.Bubble4 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/zanzibarMelia-Zanzibar-Hotel-Kiwengwa-Exterior.JPEG) center no-repeat;
  background-size: cover;
}

.Bubble5 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/baker-tilly-www-2664aabf-3866-47f0-ad17-64307f6e6bfc_mergers.jpg) center no-repeat;
  background-size: cover;
}

.Bubble6 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/sustainable-tourism-costa-rica.jpg) center no-repeat;
  background-size: cover;
}

.Bubble7 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/cape2.jpg) center no-repeat;
  background-size: cover;
}

.Bubble8 .Bubble_img {
  background: url(../src/assets/img/kingdomlivingwebsitecontent/tour4.jpg) center no-repeat;
  background-size: cover;
}

.Bubble1 .Bubble_info_dialogue {
  position: absolute;
  bottom: 8%;
  left: 13%;
  width: 40%;
}

.Bubble2 .Bubble_info_dialogue {
  position: absolute;
  bottom: 8%;
  left: 20%;
  width: 40%;
}

.Bubble3 .Bubble_info_dialogue {
  position: absolute;
  bottom: 10%;
  right: 2%;
  width: 40%;
}

.Bubble4 .Bubble_info_dialogue {
  position: absolute;
  bottom: 10%;
  left: 10%;
  width: 40%;
}

.Bubble5 .Bubble_info_dialogue {
  position: absolute;
  bottom: 20%;
  left: 30%;
  width: 40%;
}

.Bubble6 .Bubble_info_dialogue {
  position: absolute;
  bottom: 50px;
  right: 60px;
  width: 40%;
}

.Bubble7 .Bubble_info_dialogue {
  position: absolute;
  bottom: 50px;
  left: 60px;
  width: 40%;
}

.Bubble8 .Bubble_info_dialogue {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 40%;
}

/* END */










































/* HAbout */


.HAbout {
  /* width: 100vw; */
  padding: 2vw;
}

.HAI {
  /* height: 50vw; */
  /* padding: 0px !important; */
  padding: 1vw !important;
}

.HAI1 {
  height: 100%;
  width: 100%;
  background: url(./assets/img/kingdomlivingwebsitecontent/tour1.jpeg) center no-repeat;
  background-size: cover;
  border-radius: 4vw;
  padding: 4vw;
}

.HAI2 {
  height: 100%;
  width: 100%;
  background: url(./assets/img/kingdomlivingwebsitecontent/tour5.png) center no-repeat;
  background-size: cover;
  border-radius: 4vw;
  padding: 4vw;
}

.HAI_card {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  border-radius: 4vw;
  padding: 4vw;
}

.HAI_card h1 {
  color: white;
}

.HAI_card p {
  font-family: 'Gill Sans';
}

/* END */
































/* ABOUT ME */


.AboutMe {
  height: 30vw !important;
  width: 100vw !important;
  position: relative;
  background-color: #282c34;
  overflow: hidden;
}

.AB_imgBG {
  position: relative;
  height: 30vw;
  width: 100vw;
  display: flex;
}

.AB_imgBG_child {
  width: 25vw;
  height: 30vw;
  box-shadow: 0px 0px 3vw 0px rgba(0, 0, 0, 0.8);
  background-color: #282c34;
  position: relative;
}

.AB_imgBG_child1 {
  background: url(./assets/img/jpeg_large_202112291737-801bf1be61cb7e0e94e033dbdbc8d9e4.jpeg) center no-repeat;
  background-size: cover;
}

.AB_imgBG_child2 {
  background: url(./assets/img/kingdomlivingwebsitecontent/zanzibarda62dc9f316b50213660d4cda15d0093-1604084318.png) center no-repeat;
  background-size: cover;
  z-index: 1;
}

.AB_imgBG_child3 {
  background: url(./assets/img/kingdomlivingwebsitecontent/zanzibarMelia-ZA-4.jpg) center no-repeat;
  background-size: cover;
}

.AB_imgBG_child4 {
  background: url(./assets/img/kingdomlivingwebsitecontent/cape6.jpg) center no-repeat;
  background-size: cover;
}

.AB_Head_glass1 {
  position: absolute;
  height: 30vw;
  width: 100vw;
  display: flex;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 2;
}

.AB_Head_glass2 {
  position: absolute;
  height: 30vw;
  width: 100vw;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  z-index: 2;
}

.AB_Head_glass3 {
  position: absolute;
  height: 30vw;
  width: 100vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AB_Head_glass3 h1 {
  font-family: DancingScript_VariableFont_wght !important;
  color: black !important;
  font-weight: 700 !important;
  font-size: 10vw !important;
  margin: 0px !important;
  text-align: center;
  padding: 10px;
  /* background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  background: linear-gradient(to right, #570826,
      #401b03, #5d0e5d, #514a17);
      /* background: linear-gradient(to right, #f32170,
          #ff6b08, #cf23cf, #eedd44); */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  position: relative;
  top: 3vw;
}

.AB_Head_glass3 h2 {
  font-family: Borel_Regular !important;
  color: black !important;
  font-weight: 100 !important;
  font-size: 2.3vw !important;
  /* font-size: 2vw !important; */
  margin: 0px !important;
  text-align: center;
  background: linear-gradient(to left, #570826,
      #401b03, #5d0e5d, #514a17);
      /* background: linear-gradient(to right, #f32170,
          #ff6b08, #cf23cf, #eedd44); */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  position: relative;
  padding: 30px;
  top: 2vw;
}

@media (max-width: 991px) {
  .AB_Head_glass3 h2 {
    font-size: 3.3vw !important;
  }
  .AB_Head_glass3 h1 {
    top: 5vw;
  }
}

@media (max-width: 991px) {
  .AB_Head_glass3 h2 {
    font-size: 3.3vw !important;
  }
  .AB_Head_glass3 h1 {
    top: 5vw;
  }
}

@media (max-width: 785px) {
  .AB_Head_glass3 h2 {
    top: 0vw;
  }
}

.ABM_secton1Container{
  width: 100vw;
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
}

.ABM_secton1 {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  background-color: #0f621a;
}

.ABM_S1_Img_Cont{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ABM_S1_Img {
  height: 100%;
  width: 100%;
  background: url(./assets/img/kingdomlivingwebsitecontent/aboutme1.jpg) center no-repeat;
  background-size: cover;
  background-position: top;
}

.ABM_S1_Info_Cont {
  padding: 2vw !important;
  position: relative;
}

.ABM_S1_Info_Cont_ {
  background: url(./assets/img/kingdomlivingwebsitecontent/aboutme1.jpg) center no-repeat;
  background-size: cover;
  position: absolute;
  background-position: top;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  /* transform: rotatey(180deg); */
  opacity: 0.2;
}

.ABM_S1_Info_Cont p {
  font-family: 'Gill Sans';
  font-size: larger;
  padding-bottom: 3vw;
  position: relative;
}

.ABM_secton2Container {
  width: 100vw;
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
  margin-top: 20px ;
}

.ABM_secton2 {
  /* background: url(./assets/img/kingdomlivingwebsitecontent/aboutme2.jpg) center no-repeat; */
  background-size: cover;
}

.ABM_secton2 p{
  color: black;
  font-family: 'Gill Sans';
  font-size: larger;
  padding-bottom: 3vw;
}

.ABM_secton2_image1_ {
  height: 100%;
  width: 100%;
  background: url(./assets/img/kingdomlivingwebsitecontent/aboutme3.jpg) center no-repeat;
  background-size: cover;
  min-height: 250px;
}


.ABM_secton2_image2_ {
  height: 100%;
  width: 100%;
  background: url(./assets/img/kingdomlivingwebsitecontent/aboutme4.jpg) center no-repeat;
  background-size: cover;
  min-height: 250px;
}

.ABM_secton2_col {
  padding: 3vw !important;
}


.ABM_secton3Container{
  width: 100vw;
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
}

.ABM_secton3 {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  background-color: #232523;
}

.ABM_S3_Img_Cont{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ABM_S3_Img {
  height: 100%;
  width: 100%;
  background: url(./assets/img/kingdomlivingwebsitecontent/aboutme4.jpg) no-repeat;
  background-size: cover;
}

.ABM_S3_Info_Cont {
  padding: 2vw !important;
}

.ABM_S3_Info_Cont p {
  font-family: 'Gill Sans';
  font-size: larger;
  padding-bottom: 3vw;
}

/* END OF ABOUT ME */










































/* HOServices */

.HOServices {
  padding: 10px;
  padding-left: 4vw;
  position: relative;
  /* margin-top: 20px; */
  /* background-color: #29052f; */
}

.HOServices_imgBG {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: url(./assets/img/kingdomlivingwebsitecontent/map2.jpg) center no-repeat;
  background-size: cover;
  z-index: -2;
  opacity: 0.2;
}

.HOServices_imgBG_ {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
  z-index: -2;
  /* position: relative; */
}

.HOServices_imgBG_gradient {
  background-image: linear-gradient(to bottom, white, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  width: 100%;
  height: 50%;
}

.HOServices_imgBG_gradient2 {
  background-image: linear-gradient(to top, white, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  width: 100%;
  height: 50%;
}

.HOServices_h1 {
  font-family: Silent_Asia;
  width: 98%;
  background-image: linear-gradient(to right, rgba(33, 71, 18, 0.532), rgba(0, 0, 0, 0));
  padding: 0.5vw;
  font-size: 5vw;
  padding-top: 2vw;
  color: white;
}

/* Sketch
Admiration_Pains
edo
The_Bully
Silent_Asia */
.HOS {
  padding: 0px !important;
}

.HOS_serviceCard {
  padding-left: 0vw;
  padding-right: 2vw;
  display: flex;
  align-items: flex-start;
}

.bulletIndicator {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.bulletIcon {
  height: 1.4vw;
  width: 1.4vw;
  border-radius: 1vw;
  background-color: #550f62;
}

.bulletDash {
  height: 2px;
  width: 4vw;
  margin-left: 1vw;
  margin-right: 1vw;
  background-color: #550f62;
}

.bulletCard {
  width: 70vw;
  margin-bottom: 25px;
  position: relative;
  background-color: rgba(0, 0, 0, 0.639);
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.7);
}

.bulletCard h1 {
  margin: 0px;
  color: white;
  font-family: 'Gill Sans';
}

.bulletCard ul {
  color: white;
  font-family: 'Gill Sans';
  font-size: 20px;
}

.bulletCardImg {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: -1;
  background-color: #282c34;
  /* opacity: 0.1; */
}

/* .bulletCardImg1 {
  background: url(./assets//img/kingdomlivingwebsitecontent//airportSample-.jpg) center no-repeat;
  background-size: cover;
}
.bulletCardImg2 {
  background: url(./assets//img/kingdomlivingwebsitecontent//tour2.jpg) center no-repeat;
  background-size: cover;
}
.bulletCardImg3 {
  background: url(./assets//img/kingdomlivingwebsitecontent//tour4.jpg) center no-repeat;
  background-size: cover;
}
.bulletCardImg4 {
  background: url(./assets//img/kingdomlivingwebsitecontent//tour3.jpg) center no-repeat;
  background-size: cover;
}
.bulletCardImg5 {
  background: url(./assets//img/kingdomlivingwebsitecontent//Alisa-Hotel-North-Ridge-10.jpg) center no-repeat;
  background-size: cover;
}
.bulletCardImg6 {
  background: url(./assets//img/kingdomlivingwebsitecontent//airportSample5.jpg) center no-repeat;
  background-size: cover;
} */


/* @media (max-width: 991px) {
  .bulletCard{
    background-color: #13620f;
  }
} */

@media (max-width: 767px) {
  .HOServices {
    padding: 10px;
    /* padding-left: 0vw; */
  }

  .HOServices_h1 {
    margin-left: 4vw;
  }

  .HOS_serviceCard {
    padding-left: 4vw;
    padding-right: 4vw;
    display: flex;
    align-items: flex-start;
  }

  .bulletCard {
    width: 100vw;
  }
}


/* END */

















































/* .latestnews SECTION */


.latestnews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.latestnews h1 {
  font-family: 'Gill Sans MT';
  font-weight: lighter;
  font-size: 50px;
  color: rgb(0, 76, 0);
}

.lsec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SnapListNav {
  cursor: pointer;
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: seagreen;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-weight: 900;
  font-family: cursive;
  padding-bottom: 5px;
  transition: 0.6s;
}

.SnapListNav1 {
  left: 3%;
  padding-right: 2px;
}

.SnapListNav2 {
  right: 3%;
  padding-left: 2px;
}

.SnapListNav:hover {
  transform: scale(1.3);
}

.SnapListNav:active {
  transform: scale(0.9);
}

.SnapItem_Box_p {
  color: whitesmoke;
  font-size: 35px;
  padding: 30px;
}


/* END */







/* .heading SECTION */


.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Card_withImgBg {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.484);
  position: absolute;

}

.heading hr {
  width: 60%;
  background-color: black;
  border-color: black;
  opacity: 0.1;
}

.heading h1 {
  font-family: 'Gill Sans MT';
  font-weight: lighter;
  font-size: 4.2rem;
  color: rgb(76, 0, 0);
}

.heading .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading .section p {
  color: rgb(54, 59, 55);
  font-size: 1.5rem;
  text-align: center;
  font-family: sans-serif;
  padding-left: 5rem;
  padding-right: 5rem;
  letter-spacing: 0.3rem;
}

.heading .section a {
  color: white;
  font-size: 20px;
  text-align: center;
  background-color: rgb(181, 55, 5);
  background-image: linear-gradient(to right, rgb(181, 55, 5), rgb(104, 31, 3));
  border-radius: 3px;
  padding: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 30px;
  text-decoration: none;
  transition: 0.5s;
}

.heading .section a:hover {
  transform: scale(1.04);
}

.test-class {
  background-color: rgba(0, 0, 0, 0.214) !important;
}

.heading .READ a .fa {
  background-color: rgb(70, 70, 70);
  color: aliceblue;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 4px;
  padding-right: 4px;
}

.heading .READ a {
  color: rgb(70, 70, 70);
  font-size: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  border-radius: 3px;
  padding: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 30px;
  text-decoration: none;
  border: 1px solid gray;
  transition: 0.5s;
}

.heading .READ a:hover {
  transform: scale(1.04);
}

.heading .READ a .READ {
  border: 0px solid rgba(128, 128, 128, 0);
}





.READ_Search {
  opacity: 0.5;
  z-index: -3;
}

.readSection {
  width: 105%;
  padding-top: 2rem;
}

.readSection_box {
  height: 500px;
  overflow: hidden;
}

.filteredResultEmpty {
  width: 100%;
  background-color: rgba(128, 128, 128, 0.081);
  padding: 15px;
}

.ColumnRead2 {
  /* padding-left: 30px !important; */
  padding-top: 0px !important;
  margin-top: 0px !important;
}

@media (max-width: 991px) {
  .ColumnRead2 {
    padding-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .ColumnRead2 {
    padding-left: 30px !important;
  }
}



.CoolVideoPlayer {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 25px !important;
}

.CoolVideoPlayer_div {
  background-color: black;
  padding-left: 0px;
  margin-left: 0px;
  padding: 0px !important;
  overflow: hidden;
  border-radius: 20px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


.modal_container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.535);
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  transition: 0.6s;
  z-index: 1000;
  cursor: pointer;
}

.modal_ {
  position: absolute;
  right: 0%;
  top: 35%;
  height: 30%;
  width: 10%;
  background-color: rgb(221, 221, 198);
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal_ p {
  padding-left: 0px !important;
  margin-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 0px !important;
}





.externalUrl {
  background-color: rgb(56, 56, 255) !important;
  background-image: linear-gradient(to left, rgb(56, 56, 255), rgb(66, 66, 176)) !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.boldDialogue_ {
  padding-right: 17rem !important;
  padding-left: 17rem !important;
  color: rgb(17, 16, 16) !important;
  line-height: 3.5rem;
  font-weight: 500 !important;
  padding-bottom: 1rem;
}

.boldDialogue2_ {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  color: rgb(17, 16, 16) !important;
  line-height: 3.5rem;
  font-weight: 500 !important;
  padding-bottom: 1rem;
}

.boldUniqueDialogue_ {
  padding-right: 17rem !important;
  padding-left: 17rem !important;
  color: rgb(66, 66, 66) !important;
  line-height: 3rem;
  font-weight: 500 !important;
  padding-bottom: 1rem;
}

@media (max-width: 991px) {
  .boldDialogue_ {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    color: rgb(17, 16, 16) !important;
  }

  .boldDialogue2_ {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    color: rgb(17, 16, 16) !important;
  }

  .boldUniqueDialogue_ {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
    color: rgb(66, 66, 66) !important;
  }
}

.heading .section .multipleDropDown {
  /* background-color: aqua; */
}

.heading .section .multipleDropDown .multipleDropDown_head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 4%;
  cursor: pointer;
  padding-bottom: 1.6rem;
  padding-top: 2rem;
}

.heading .section .multipleDropDown .multipleDropDown_head i {
  color: green;
}

.heading .section .multipleDropDown .multipleDropDown_head p {
  margin: 0px;
  padding: 0px;
  padding-left: 1rem;
  font-weight: 600;
}

.heading .section .multipleDropDown .dropDown_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  text-align: left;
  cursor: pointer;
  padding-left: 25px !important;
  overflow: hidden;
  transition: 0.6s;
}


@media (max-width: 991px) {
  .heading .section .multipleDropDown {
    padding-left: 0px !important;
    margin-left: 0px;
  }

  .heading .section .multipleDropDown .multipleDropDown_head {
    padding-left: 0%;
  }

  .heading .section .multipleDropDown .dropDown_ {
    padding-left: 0%;
  }

  .heading .section .multipleDropDown .dropDown_ ul {
    padding-left: 10rem;
  }

  .heading .section .multipleDropDown .dropDown_ p {
    padding: 0px;
  }
}


.heading .section .multipleDropDown .closed {
  height: 0px;
}

.heading .section .multipleDropDown .dropDown_ p {
  text-align: left;
  cursor: text;
  padding-top: 5px;
  padding-bottom: 10px;
  color: rgb(98, 98, 98);
  letter-spacing: 0.1rem;
}

.heading .section .multipleDropDown .dropDown_ ul {
  text-align: left;
  cursor: text;
  padding-top: 5px;
  padding-bottom: 10px;
}

.heading .section .multipleDropDown .dropDown_ li {
  font-size: 1.4rem;
  font-family: sans-serif;
  color: rgb(98, 98, 98);
  letter-spacing: 0.1rem;
}



/* END */

.FadingGradient {
  width: 100%;
  height: 30px;
}



/* .footerSECTION */

.footer {
  width: 100%;
  height: 57px;
  background-color: rgb(26, 28, 32);
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 130px;
  position: relative;
  z-index: 10;
}

.footer {
  font-size: 1.1rem;
}

.footer .logo_ {
  display: flex;
}

.footer .logo_ div {
  margin-left: 10px;
  margin-right: 10px;
}

.footer .logo_ .NavBar_buttons_a a {
  font-size: 1rem;
  transition: 00.6s;
  text-decoration: none;
}

.footer .logo_ .NavBar_buttons_a .active {
  font-size: 1rem;
  transition: 00.6s;
  text-decoration: none;
  color: rgb(127, 212, 0);
}

.footer .logo_ a {
  color: aliceblue;
}

@media (max-width: 991px) {
  .footer {
    padding-left: 15px;
    padding-right: 0px;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .footer .logo_ .NavBar_buttons_a a {
    font-size: 0.9rem;
    transition: 00.6s;
    text-decoration: none;
  }

  .footer p {
    font-size: 0.9rem;
    transition: 00.6s;
    text-decoration: none;
  }
}

/* @media (max-width: 661px) {
  .footer .logo_ {
    flex-direction: column;
  }

  .logo_col_6 {
    margin-top: 5px;
    justify-content: flex-end;
  }
} */
























































/* NAVBAR */

.NavBar_logo {
  position: fixed;
  top: 8px;
  left: 8px;
  /* width: 205px; */
  height: 10vw;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}

.NavBar_logo .logo {
  width: 10vw;
  height: 10vw;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.829);
  /* background-color: rgba(243, 236, 230, 0.362); */
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  justify-content: center;
  align-items: center;
  display: flex;
}

.NavBar_logo .logo img {
  width: 9vw;
  height: 9vw;
  margin: 0px;
}

@media (max-width: 991px) {
  .NavBar_logo .logo {
    width: 13vw;
    height: 13vw;
  }

  .NavBar_logo .logo img {
    width: 12vw;
    height: 12vw;
  }
}

.NavBar_logo h1 {
  color: white;
  font-size: small;
  margin: 0px;
  font-family: 'Courier New', Courier, monospace;
}

.NavBar_logo p {
  color: white;
  font-size: x-small;
  margin: 0px;
  font-family: 'Courier New', Courier, monospace;
}


.NavBar_logo2 {
  position: fixed;
  top: 8px;
  left: 178px;
  width: 130px;
  height: 48px;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}






.NavBar_buttons {
  position: fixed;
  top: 8px;
  right: 8px;
  width: 54%;
  height: 68px;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}

.NavBar_buttons .logo {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(39, 38, 37, 0.362);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.NavBar_buttons .logo a {
  color: aliceblue;
  text-decoration: none;
  cursor: pointer;
  transition: 0.6s;
  /* text-shadow: 0px 0px 20px black; */
}

.NavBar_buttons .logo .active {
  color: rgb(127, 212, 0);
}

.NavBar_buttons_a {
  transition: 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavBar_buttons_a:hover a {
  transform: scale(1.2);
  color: rgb(127, 212, 0);
}

.NavBar_buttons_a:hover .dropDown {
  height: 100px;
  opacity: 1;
}

.NavBar_buttons_a:hover .dropDown2 {
  height: 260px;
  opacity: 1;
}

.NavBar_buttons_a .dropDown {
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.NavBar_buttons_a .dropDown p {
  color: black;
  font-size: smaller;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-radius: 5px;
  transition: 0.6s;
}

.NavBar_buttons_a .dropDown a {
  color: black;
}

.NavBar_buttons_a .dropDown p:hover {
  background-color: bisque;
}

.NavBar_buttons_a .dropDown .dropDown_tabIndicator {
  height: 20px;
  width: 20px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: rgba(255, 228, 196, 0.656);
}

.NavBar_buttons_a .dropDown .dropDown_dialogue {
  background-color: rgba(255, 228, 196, 0.656);
  width: 200px;
  border-radius: 5px;
  padding: 10px;
}

.NavBar_buttons_a .dropDown .dropDown_dialogue .active {
  background-color: rgb(251, 208, 154);
}


.NavBar_buttons_a .dropDown2 {
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.6s;
  height: 0px;
  opacity: 0;
  overflow: hidden;
}

.NavBar_buttons_a .dropDown2 p {
  color: black;
  font-size: smaller;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  border-radius: 5px;
  transition: 0.6s;
}

.NavBar_buttons_a .dropDow2 a {
  color: black;
}

.dropDown_dialogue_a {
  width: 100%;
  height: 100%;
  /* background-color: rgb(213, 213, 213); */
  display: flex;
  font-size: smaller;
  padding-left: 15px;
}

.dropDown_dialogue2_active {
  background-color: rgb(251, 208, 154);
}

.NavBar_buttons_a .dropDown2 p:hover {
  background-color: bisque;
}

.NavBar_buttons_a .dropDown2 .dropDown_tabIndicator {
  height: 20px;
  width: 20px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background-color: rgba(255, 228, 196, 0.656);
}

.NavBar_buttons_a .dropDown2 .dropDown_dialogue {
  background-color: rgba(255, 228, 196, 0.656);
  width: 200px;
  border-radius: 5px;
  padding: 10px;
}

.NavBar_buttons_a .dropDown .dropDown_dialogue .active {
  background-color: rgb(251, 208, 154);
}

.NavBar_buttons_a .dropDown2 .dropDown_dialogue a {
  color: black;
}

.NavBar_buttons .fa {
  color: aliceblue;
  cursor: pointer;
  transition: 0.6s;
  text-decoration: none;
}

.NavBar_buttons .fa:hover {
  transform: scale(1.4);
}

.NavBar_buttons .fa:active {
  transform: scale(0.9);
}



.NavBar_buttons_mobile {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.422);
  overflow: hidden;
  transition: 00.6s;
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}

.NavBar_buttons_mobile .logo {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 370px;
  height: 100%;
  border-radius: 0px;
  /* background-color: rgba(39, 38, 37, 0.362); */
  background-color: rgba(243, 236, 230, 0.862);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow-y: scroll;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_close {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_close .fa {
  font-size: 40px;
  transition: 00.6s;
  cursor: pointer;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_ {
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid rgba(128, 128, 128, 0.391);
  border-width: 89%;
  text-align: left;
  padding: 12px;
  text-decoration: none;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_ .children {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_ .children .fa-plus {
  transition: 0.6s;
  font-weight: 100;
  opacity: 0.5;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_ .children .rotate {
  transform: rotate(-45deg);
}

.NavBar_buttons_a_ .dropDown {
  width: 100%;
  height: inherit;
  padding-left: 20px;
  overflow: hidden;
  transition: 0.4s;
}

.NavBar_buttons_mobile .logo a {
  text-decoration: none;
  font-family: Tahoma;
  color: black;
  font-weight: 500;
}

.NavBar_buttons_mobile .logo .NavBar_buttons_a_ .active {
  color: red;
}

.NavBar_buttons_a_ .dropDown .dropDown_dialogue .active {
  background-color: rgba(251, 208, 154, 0.4);
}

.NavBar_buttons_mobile .logo a:hover {
  text-decoration: none;
  color: red;
}

.NavBar_buttons_mobile .fa-user {
  /* color: aliceblue; */
  margin-left: 13.5%;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.6s;
  text-decoration: none;
}

.NavBar_buttons_mobile .fa:hover {
  transform: scale(1.4);
}

.NavBar_buttons_mobile .fa:active {
  transform: scale(0.9);
}








.social_NavBar_ {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social_pDiv_ .social_p {
  color: black;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.social_NavBar_ .social_NavBarIcons {
  background-color: rgba(0, 0, 0, 0.57);
  padding: 2px;
  width: 20px;
  height: 20px;
}



.social_NavBar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 4%;
  height: 100%;
  /* background-color: rgba(243, 236, 230, 0.962); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social_NavBarIcons {
  width: 15px;
  height: 15px;
  margin: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.385);
  transition: 0.4s;
  cursor: pointer;
  border-radius: 3px;
}

.social_NavBarIcons:hover {
  transform: scale(1.2);
}

.social_NavBarIcons:active {
  transform: scale(1.1);
}

.social_line {
  width: 1px;
  height: 60px;
  margin: 10px;
  background-color: aliceblue;
}

.social_pDiv {
  transform: rotate(-90deg);
  width: 220px;
  padding-bottom: 3px;
  margin-bottom: 100px;
}

.social_p {
  font-size: 15px;
  color: aliceblue;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-shadow: 0px 0px 20px black;
}


/* END OF NAV BAR */



/* LogIn_Container */

.LogIn_Container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.536);
  position: fixed;
  top: 0px;
  left: 0px;
  transition: 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogIn_Container .NavBar_buttons_a_close {
  position: absolute;
  top: 2%;
  right: 2%;
  font-size: xx-large;
  color: aliceblue !important;
}

.LogIn_Container_glass {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.LogIn_System {
  width: 50%;
  transition: 0.6s;
  border-radius: 20px;
}

.boxShadowClass {
  /* transition: 0.1s; */
  box-shadow: 0px 5px 10px 2px rgba(0, 0, 0, 0.414);
}

.LogIn_System_Register {
  height: 80%;
}

.LogIn_System_logIn {
  height: 60%;
}

.LogIn_System_ {
  width: 100%;
  height: 100%;
  background-color: rgba(250, 235, 215, 0.665);
  transition: 0.6s;
  border-radius: 20px;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow: hidden;
  padding: 0px;
}

.LogIn_System_ .col-md-8 {
  padding: 20px;
  transition: 0.4s;
}

.LogIn_System_ .col-md-8 p {
  padding: 5px;
  padding-left: 20px;
}

.Heading {
  color: #282c34;
  font-weight: bold;
  font-size: xx-large;
  font-family: Verdana;
  text-transform: uppercase;
}

.LogIn_System_ p {
  color: #282c34;
  transition: 0.4s;
}

.LogIn_System_ p span {
  color: brown;
  transition: 0.4s;
  cursor: pointer;
}

.LoginButton {
  background-color: rgb(105, 2, 14);
  padding: 10px;
  margin-left: 10px;
  border-radius: 10px;
  width: 100px;
  padding-right: 20px;
  color: aliceblue !important;
  margin-top: 3px;
  margin-bottom: 30px;
}

@media (min-width: 991px) {
  .LogIn_System_ .col-md-4 {
    background-color: brown;
    background-image: -moz-linear-gradient(-65deg, brown, rgb(132, 15, 15), rgb(132, 15, 15), brown);
    background-image: -o-linear-gradient(-65deg, brown, rgb(132, 15, 15), rgb(132, 15, 15), brown);
    background-image: -webkit-linear-gradient(-65deg, brown, rgb(132, 15, 15), rgb(132, 15, 15), brown);
    background-image: linear-gradient(-65deg, brown, rgb(132, 15, 15), rgb(132, 15, 15), brown);
    height: 100%;
    margin-right: 0px;
    padding-right: 0px;
    right: -10px;
    padding: 0px;
  }

  .LogIn_System_ .col-md-4 div {
    height: 100%;
    width: 100%;
    background: url(../src/assets/img/dating-african-favicon.png) center no-repeat;
    background-size: cover;
  }
}


@media (max-width: 991px) {
  .LogIn_System {
    width: 80%;
    height: 80%;
  }
}


/* END OF LogIn_Container */





















































/* Packages - and - OrderForm */




.tp_a {
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  background-color: rgb(113, 42, 0);
  color: white;
  transition: 0.6s;
  font-size: 16px;
  font-family: sans-serif;
  /* margin-top: 10px; */
}

.tp_a:hover {
  background-color: rgb(58, 8, 7);
  transform: scale(1.1);
  color: white;
}

.tp_a:active {
  background-color: rgb(58, 8, 7);
  transform: scale(0.9);
}


.tp_head_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2);
}

.tp_head_box_hr {
  width: 697px;
  height: 30px;
  background-color: rgba(205, 164, 15, 0.4);
}

.tp_head_box_div {
  width: 697px;
  /* height: 300px; */
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  transition: 0.6s;
}

.tp_head_box_div h4 {
  font-family: 'Gill Sans';
  text-transform: uppercase;
  font-weight: 600;
  font-size: larger;
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.tp_head_box_div h3 {
  font-family: 'Gill Sans';
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 600;
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.tp_head_box_div h1 {
  /* font-family:fantasy; */
  font-family: Allura-Regular;
  font-size: 95px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(179, 54, 16);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.tp_head_box_div a {
  padding: 10px;
  background-color: rgb(113, 42, 0);
  color: white;
  margin-top: 10px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 300;
}

.tp_head_box_div a:hover {
  padding: 10px;
  background-color: rgb(80, 52, 35);
  color: white;
  margin-top: 10px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 300;
  transform: scale(1.1);
}

.tp_head_box-after_div {
  width: 100%;
  height: 0px;
  display: none;
  transition: 0.6s;
}

.tp_absolute_image_holder {
  width: 100%;
  height: 80vh;
  background-color: rgba(179, 19, 16, 0.4);
  background: url(./assets/img/Alisa-Hotel-North-Ridge-10.jpg) center fixed no-repeat;
  background-size: cover;
}

.package_list {
  /* list-style-type: none; */
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 0px;
  padding-right: 0px !important;
  padding-bottom: 10px;
  padding-left: 0px !important;
  position: relative;
  left: -25px;
}

.package_list .fas {
  color: rgba(179, 19, 16, 0.4);
  margin-right: 10px;
}


.acm_heading {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  background-color: rgb(58, 41, 7);
  color: white;
  position: relative;
}

.acm_heading h1 {
  font-family: 'Gill Sans MT';
}

.acm_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.acm_body h1 {
  margin-top: 100px;
  letter-spacing: 10px;
}

.acm_body p {
  font-family: 'Gill Sans', 'Gill Sans MT';
}

.acm_body .acm_card {
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.acm_body .acm_card img {
  width: 94%;
  height: 96%;
  object-fit: cover;
  position: absolute;
  top: 2%;
  left: 3%;
}

.acm_card_div {
  width: 94%;
  height: 96%;
  position: absolute;
  top: 2%;
  left: 3%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: 0.6s;
  opacity: 0;
}

.acm_card_div:hover {
  opacity: 1;
}

.acm_body .col-md-6 {
  padding: 0px !important;
}

.acm_textcard {
  padding-right: 7vw !important;
  padding-top: 20px !important;
}

.acm_textcard p {
  font-size: larger;
}


.rtn {
  width: 100%;
  background-color: rgb(21, 16, 179);
  background-image: linear-gradient(to bottom, rgb(58, 8, 7), rgb(2, 68, 2));
  display: flex;
  justify-content: center;
  padding: 30px;
}

.rtn_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  padding: 30px;
}

.pck_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pck_ {
  background-color: #ffffff;
  margin: 10px;
  /* width: 40vw; */
  padding: 50px;
  border-radius: 2px;
  text-align: center;
  /* padding-top: 30px; */
  /* padding-top: 30px; */
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.46);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pck_ h3 {
  font-family: cursive;
  font-size: large;
  font-weight: bolder;
}

.pck_ p {
  font-family: 'Courier New';
  /* font-size: large; */
  font-weight: bolder;
  padding: 0px !important;
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.8)
}

.pck_ a {
  padding: 10px;
  background-color: rgb(73, 30, 4);
  color: white;
  margin-top: 10px;
  font-size: small;
  margin-top: 20px;
  border-radius: 2px;
  font-family: sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.26);
}

.pck_ a:hover {
  padding: 10px;
  background-color: rgb(80, 52, 35);
  color: white;
  margin-top: 10px;
  font-size: small;
  margin-top: 20px;
  border-radius: 2px;
  font-family: sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.26);
}

.rtn h4 {
  font-family: 'Gill Sans';
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: 600;
  font-size: xx-large;
  color: rgb(255, 255, 255);
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.rtn h1 {
  /* font-family:fantasy; */
  font-family: Allura-Regular;
  font-size: 100px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.cool_hr {
  width: 90%;
  height: 2px;
  background-color: rgba(179, 19, 16, 0.4);
  border-radius: 20;
  margin-top: 10;
  margin-bottom: 10;
}


._Trip_HiglightsCP {
  padding-top: 70px !important;
}

._Trip_HiglightsCP_ {
  padding-bottom: 4vw !important;
}

@media (max-width: 990px) {

  .tp_absolute_image_holder {
    width: 100%;
    height: 60vh;
    background-color: blue;
    background: url(./assets/img/Alisa-Hotel-North-Ridge-10.jpg) center no-repeat;
    background-size: cover;
  }

  ._Trip_HiglightsCP {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ._Trip_HiglightsCP_ {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 770px) {

  .acm_body .acm_card {
    height: 400px;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .acm_textcard {
    padding-right: 7vw !important;
    padding-left: 7vw !important;
    padding-top: 20px !important;
  }
}

@media (max-width: 720px) {
  .tp_head_box_hr {
    display: none;
  }

  .tp_head_box {
    display: none;
  }

  .tp_head_box-after_div {
    display: flex;
    height: 230px;
  }

  .tp_absolute_image_holder {
    width: 100%;
    height: 40vh;
    background-color: blue;
  }

  .acm_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
  }

  .acm_body .acm_card {
    height: 400px;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .acm_body .acm_card img {
    width: 96%;
    height: 96%;
    object-fit: cover;
    position: absolute;
    top: 2%;
    left: 2%;
  }

  .acm_card_div {
    width: 96%;
    height: 96%;
    position: absolute;
    top: 2%;
    left: 2%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .acm_textcard {
    padding-right: 7vw !important;
    padding-left: 7vw !important;
    padding-top: 20px !important;
  }


}




.OrderSummary {
  width: 100vw;
  padding-left: 5vw;
  padding-right: 2vw;
}

.O_S_container {
  width: 100%;
  border-radius: 10px;
  position: relative;
  top: -30px;
  background-color: #efefef;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.283);
  padding: 10px;
  padding-top: 30px;
  margin: 0;
}

.O_S_container h6 {
  /* color: #565b72 !important; */
  color: #565b72;
  font-family: sans-serif;
}

.O_S_container h5 {
  /* color: #565b72 !important; */
  color: #565b72;
  font-family: sans-serif;
  font-size: larger;
}

.O_S_container p {
  /* color: #565b72 !important; */
  color: #565b72;
  font-family: sans-serif;
}

.O_S_col {
  border-bottom: 1px solid rgba(0, 0, 0, 0.063);
}

.O_S_col_ {
  padding: 4vw;
}

@media (max-width: 768px) {
  .O_S_container {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

@media (min-width: 768px) {
  .O_S_container {
    padding-left: 0vw;
    padding-right: 0vw;
  }

  .O_S_col {
    border-bottom: 0px solid rgba(0, 0, 0, 0.063);
    border-right: 1px solid rgba(0, 0, 0, 0.063);
  }
}

@media (min-width: 992px) {
  .O_S_container {
    padding-left: 6vw;
    padding-right: 6vw;
  }
}


.O_S_InputField_container {
  padding: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 15px;
}

.O_S_InputField_subCcontainer {
  background-color: #efefef;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(5, 5, 5, 0.134);
  border-radius: 10px;
  position: relative;
}

.O_S_InputField_subCcontainer p {
  font-size: 12px;
  position: absolute;
  background-color: #efefef;
  top: -12px;
  left: 5px;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
}

.O_S_InputField {
  width: 100%;
  height: 100%;
  margin: 0px;
  background-color: rgba(0, 0, 0, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  padding: 5px;
  border-radius: 10px;
}





.card {
  background-color: white;
  padding: 10px 20px 11px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #afafaf;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: rgb(8, 53, 8);
  border: 0;
  border-radius: 30px;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}

.pay-button:disabled:hover {
  box-shadow: none;
}

.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}











/* end of Packages - and - OrderForm */



















/* Cape Town */





.CapeHeaderBg {
  width: 100vw;
  height: 90vh;
  /* background-color: rgba(179, 19, 16, 0.4); */
  display: flex;
}

.capeSlide {
  width: 100vw;
  position: relative;
}

.capeSlide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.capeSlide_ {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: relative; */
}

.slide1Cont {
  width: 20vw;
}

.capeSlide_textholder1 {
  height: 20vw;
  width: 90vh;
  background-color: #e23bff95;
  transform: rotate(-90deg);
  position: absolute;
  display: flex;
  align-items: flex-end;
  padding: 0vw;
  padding-left: 50px;
}

.capeSlide_textholder1 h4 {
  /* font-family:fantasy; */
  font-family: 'Gill Sans';
  /* font-family: Allura-Regular; */
  font-size: 2vw;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.slide2Cont {
  width: 35vw;
}

.capeSlide_textholder2 {
  height: 35vw;
  width: 90vh;
  background-color: #3b5fff95;
  transform: rotate(-90deg);
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0vw;
  padding-left: 10px;
}

.capeSlide_textholder2 h1 {
  /* font-family:fantasy; */
  font-family: 'Gill Sans';
  /* font-family: Allura-Regular; */
  font-size: 17vw;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.slide3Cont {
  width: 30vw;
}

.capeSlide_textholder3 {
  height: 30vw;
  width: 90vh;
  background-color: #076469ac;
  transform: rotate(-90deg);
  position: absolute;
  padding: 0vw;
  padding-left: 20px;
}

.capeSlide_textholder3 span {
  /* font-family:fantasy; */
  font-family: 'Gill Sans';
  /* font-family: Allura-Regular; */
  font-size: 10vw;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.capeSlide_textholder3 h2 {
  /* font-family:fantasy; */
  font-family: 'Gill Sans';
  /* font-family: Allura-Regular; */
  font-size: 6.5vw;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.capeSlide_textholder3 p {
  /* font-family:fantasy; */
  font-family: 'Gill Sans';
  /* font-family: Allura-Regular; */
  font-size: 20px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}

.slide4Cont {
  width: 15vw;
}

.capeSlide_textholder4 {
  height: 15vw;
  width: 90vh;
  background-color: #3bcbff95;
  transform: rotate(-90deg);
  position: absolute;
}

.capeSlide_textholder4 h1 {
  /* font-family:fantasy; */
  font-family: 'Gill Sans';
  /* font-family: Allura-Regular; */
  font-size: 95px;
  /* margin-top: 20px;
  margin-bottom: 20px; */
  color: rgb(255, 255, 255);
  /* font-weight: bold; */
  /* text-transform: uppercase; */
  /* 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif */
}


.capeTownTripHighlights {}

.capeTownTripHighlights_ {
  width: 105vw;
  height: 100%;
  /* background-color: #ffffff; */
  position: relative;
  background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0.687), rgba(255, 255, 255, 0));
  padding-bottom: 100px;
}

.CPabsoluteImage {
  background-color: rgba(179, 19, 16, 0.4);
  background: url(./assets/img/kingdomlivingwebsitecontent/16256-113963-f64655723_3xl.jpg) center fixed no-repeat;
  background-size: cover;
}

.CP_absolute_image_holder {
  width: 100%;
  height: 60vh;
}

.CP_absolute_image_holder_ {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.415), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

.CPacm_heading {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
}

.CPacm_heading div {
  height: 100%;
  width: 25%;
}

.CPacm_heading1 {
  background-color: #3bcbff95;
}

.CPacm_heading2 {
  background-color: #076469ac;
}

.CPacm_heading3 {
  background-color: #3b5fff95;
}

.CPacm_heading4 {
  background-color: #e23bff95;
}

.CP_link {
  background-color: #550f62 !important;
}




/* end of Cape town */



































/* ZANZIBAR  */


.ZANZIBAR_section {
  width: 100vw;
  height: 50vw;
  position: relative;
  background: url(./assets/img/kingdomlivingwebsitecontent/ZANZIBAR.jpeg) center no-repeat;
  background-size: cover;
}

.ZANZIBAR_ {
  height: 13vw;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(26, 28, 32, 1), rgba(26, 28, 32, 0.8), rgba(26, 28, 32, 0.6), rgba(26, 28, 32, 0.3), rgba(26, 28, 32, 0.1), rgba(26, 28, 32, 0));
}

.ZANZIBAR_cont {
  width: 100%;
  position: relative;
}

.ZANZIBAR_cont_ {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.Z_bg1 {
  height: 100vw;
  width: 100vw;
  position: absolute;
  background: rgba(255, 204, 0, 0.067);
  z-index: 1;
  transform: rotate(-45deg);
  top: -10vw;
  left: 50vw;
}

.Z_bg2 {
  height: 100vw;
  width: 100vw;
  position: absolute;
  background: rgba(255, 204, 0, 0.067);
  z-index: 1;
  transform: rotate(-45deg);
  top: -10vw;
  left: 60vw;
}

.Z_bg3 {
  height: 100vw;
  width: 100vw;
  position: absolute;
  background: rgba(255, 204, 0, 0.067);
  z-index: 1;
  transform: rotate(-45deg);
  top: -10vw;
  left: 70vw;
}

.ZANZIBAR_sectionFoot {
  width: 100%;
  height: 70px;
  background-color: rgb(26, 28, 32);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ZANZIBAR_sectionFoot h3 {
  color: white;
  margin: 0px;
  text-transform: capitalize !important;
  font-family: "Gill Sans MT";
  font-weight: bold;
  text-align: center;
  font-size: x-large;
  padding-left: 50px;
  padding-right: 50px;
  z-index: 1;
}

.Z_overview {
  width: 100%;
  /* background-color: rgba(26, 28, 32, 0.176); */
  background-image: linear-gradient(to bottom, rgba(26, 28, 32, 1), rgb(59, 59, 59));
  padding: 1vw;
  padding-left: 3vw;
  padding-right: 3vw;
  position: relative;
}


.Z_O {
  position: relative;
  padding: 1vw;
  border: 1px solid rgba(255, 250, 250, 0.451);
  border-left-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.Z_O_row_img {
  height: 40vh;
  background: url(./assets/img/kingdomlivingwebsitecontent/15053416.jpg) center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.Z_O_col5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Z_overview p {
  color: rgba(255, 255, 255, 0.835);
  font-family: 'Gill Sans';
  position: relative;
  z-index: 1;
}

.Z_overview h3 {
  color: rgba(255, 255, 255, 0.835);
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
  font-family: edo;
}

.Z_overview h5 {
  color: rgba(255, 255, 255, 0.835);
  margin: 0px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.Z_O_col2 {
  background: url(./assets/img/kingdomlivingwebsitecontent/zanzibarmelia-zanzibar-hotel-1.jpg) center no-repeat;
  background-size: cover;
  height: inherit;
  position: relative;
  position: relative;
  z-index: 1;
}

.Z_O_col2_bubble {
  height: 4vw;
  width: 4vw;
  border-radius: 4vw;
  background-color: rgb(255, 204, 0);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
}

.Z_O_col2_bubble1 {
  top: -2vw;
  left: -0vw;
  transform: scale(0.8);
}

.Z_O_col2_bubble2 {
  top: 1.5vw;
  left: -3.5vw;
  transform: scale(1);
}

.Z_O_col2_bubble3 {
  top: 7vw;
  left: -5.5vw;
  transform: scale(1.2);
}

.Z_O_col2_bubble4 {
  top: 13.5vw;
  left: -4.5vw;
  transform: scale(1.4);
}

.Z_O_col2_bubble5 {
  bottom: -10%;
  left: -5%;
  transform: scale(1.6);
}

.Z_O_col2_bubble:hover {
  transform: scale(0.7) !important;
}



.Z_overview_higlight {
  height: 50px;
  background: rgb(255, 204, 0);
  color: #fff;
  position: relative;
  width: 200px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  top: 1vw;
  left: 1vw;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.Z_overview_higlight:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 25px solid transparent;
  border-left: 20px solid rgb(255, 204, 0);
  z-index: 1;
}

.Z_overview_higlight h1 {
  margin: 0px;
  font-size: xx-large;
}

.Z_overview_sectionFoot {
  width: 100%;
  height: 40px;
  background-color: rgba(59, 59, 59, 1);
  /* background-image: linear-gradient(to bottom, rgba(59, 59, 59, 1), rgba(59, 59, 59, 0.9), rgba(59, 59, 59, 0.6), rgba(59, 59, 59, 0.3), rgba(59, 59, 59, 0.1), rgba(59, 59, 59, 0)); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Z_overview_sectionFoot_ {
  height: 50px;
  background: rgb(255, 204, 0);
  color: #fff;
  position: relative;
  width: 100px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  bottom: -50px;
  left: 3vw;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 13;
  transform: rotate(90deg);
}

.Z_overview_sectionFoot_:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 25px solid transparent;
  border-left: 20px solid rgb(255, 204, 0);
  z-index: 1;
}

.Z_link {
  background-color: rgb(255, 204, 0);
  color: rgba(14, 14, 14, 0.769);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
}


.Zpackage_list {
  /* list-style-type: none; */
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 0px;
  padding-right: 0px !important;
  padding-bottom: 10px;
  padding-left: 0px !important;
  position: relative;
  left: -25px;
}

.Zpackage_list .fas {
  color: rgba(74, 6, 5, 0.4);
  margin-right: 10px;
}


.ZabsoluteImage {
  background-color: rgba(179, 19, 16, 0.4);
  background: url(./assets/img/kingdomlivingwebsitecontent/Tanzania1300x820.1673895665.png) center fixed no-repeat;
  background-size: cover;
}

.YourTrip_Destination {
  width: 100%;
}

.YTD_top {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, rgba(26, 28, 32, 1), rgba(26, 28, 32, 1));
}

.YTD_bottom {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, rgba(26, 28, 32, 1), rgba(26, 28, 32, 1));
}

.YTD_Heading_cont {
  width: 100%;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  /* background-color: rgb(58, 41, 7); */
  background-image: linear-gradient(to bottom, rgba(26, 28, 32, 1), rgba(0, 0, 0, 0.7));
  color: white;
  position: relative;
}

.YTD_Heading_cont h1 {
  font-family: 'Gill Sans MT';
  color: white;
}

.YTD_Body {
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-image: linear-gradient(to top, rgba(26, 28, 32, 1), rgba(0, 0, 0, 0.7));
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 4vw;
}

.YTD_Body h3 {
  margin: 0px;
  color: white;
  font-family: edo;
  border: 0.5px solid rgba(255, 255, 255, 0.687);
  padding: 10px;
}

.YTD_Body p {
  font-family: 'Gill Sans';
  font-size: large;
}

.YTD_Body .col-md-6 {
  border: 0.5px solid rgba(255, 255, 255, 0.687);
  padding: 10px;
}

.absolute_YTD {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(26, 28, 32, 1), rgba(26, 28, 32, 0.1));
  top: 0px;
}

.absolute_YTD__ {
  height: 5vw;
  width: 10vw;
  background: rgb(255, 204, 0);
  color: #fff;
  position: relative;
  text-align: center;
  line-height: 40px;
  position: absolute;
  bottom: -3vw;
  right: 3vw;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: ; */
  transform: rotate(90deg);
}

.absolute_YTD__:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 2.5vw solid transparent;
  border-left: 20px solid rgb(255, 204, 0);
  z-index: 1;
}



/* END - ZANZIBAR  */













































/* Book Travel Tours */


.BTT_container_absoluteBG {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.BTT_container {
  width: 100vw;
  z-index: 1;
  background-color: rgba(102, 71, 5, 0.719);
  position: relative;
  padding-left: 7vw;
  padding-right: 7vw;
}

.BCS_H {
  text-align: center;
  padding-left: 3vw;
  padding-left: 3vw;
}

@media (max-width: 991px) {
  .BTT_container {
    padding-left: 2.8vw;
    padding-right: 2vw;
  }
}

.BTT_container_absoluteBG2 {
  width: 100vw;
  height: 30vw;
  /* background-image: linear-gradient(to top, #00000000, rgba(255, 255, 255, 0.598), white); */
  background-image: linear-gradient(to bottom, rgba(102, 71, 5, 0), rgba(102, 71, 5, 0.719));
  position: absolute;
  left: 0vw;
  top: -30vw;
}

.BTT_container_sub {
  background-color: white;
  position: relative;
  top: -10vw;
  border-top-left-radius: 5vw;
  border-top-right-radius: 5vw;
  /* margin-bottom: -10vw; */
  /* padding-bottom: 4vw; */
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.367);
}

.Tours_ {
  width: 100%;
  position: relative;
  padding: 15px;
}

.ToursCol {
  padding: 0vw;
  /* background-color: #04ac1b; */
}

.ToursCol_child {
  /* background-color: rgba(206, 2, 2, 0.805); */
  height: 40vw;
  width: 100%;
  border-radius: 3vw;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.23);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.TCSlide {
  height: 150%;
  width: 150%;
  position: absolute;
  transform: rotate(-45deg);
}

.TCS_Dark {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.TCS {
  width: 100%;
  height: 33%;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 1) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.TCS h1 {
  color: white;
  font-weight: 900;
  font-size: 5vw;
  margin: 0px;
  position: relative;
  z-index: 2;
}

.TCS h2 {
  color: white;
  font-weight: 900;
  font-size: 2vw;
  margin: 0px;
  position: relative;
  z-index: 2;
}

.TCS h3 {
  color: white;
  font-weight: 900;
  font-size: 1.3vw;
  margin: 0px;
  padding-left: 15vw;
  padding-right: 15vw;
  text-align: center;
  margin-top: 1vw;
  position: relative;
  z-index: 2;
}

.TCS a {
  background-color: rgba(255, 255, 255, 0.605);
  color: black;
  z-index: 2;
  font-family: 'Gill Sans';
  padding: 1vw;
  font-size: 1.3vw;
  transition: 0.6s;
}

.TCS a:hover {
  color: black;
  transform: scale(1.1);
  opacity: 0.8;
}

.TCS a:active {
  color: black;
  transform: scale(0.95);
}

.TCS1 a {
  position: absolute;
  bottom: 10%;
}

.TCS3 a {
  position: absolute;
  top: 10%;
}


.TCSlide_Ghana .TCS1 {
  background: url(./assets/img/mole-national-park_.jpg) center no-repeat;
  background-size: cover;
}

.TCSlide_Ghana .TCS2 {
  background: url(./assets/img/jpeg_large_202112291737-801bf1be61cb7e0e94e033dbdbc8d9e4.jpeg) center no-repeat;
  background-size: cover;
}

.TCSlide_Ghana .TCS3 {
  background: url(./assets/img/iStock_000022879854_Medium_623-db33aef.jpg) center no-repeat;
  background-size: cover;
}


.TCSlide_Cape .TCS1 {
  background: url(./assets/img/kingdomlivingwebsitecontent/cape1.jpg) center no-repeat;
  background-size: cover;
}

.TCSlide_Cape .TCS2 {
  background: url(./assets/img/kingdomlivingwebsitecontent/cape3.jpg) center no-repeat;
  background-size: cover;
}

.TCSlide_Cape .TCS3 {
  background: url(./assets/img/kingdomlivingwebsitecontent/cape5.jpg) center no-repeat;
  background-size: cover;
}



.TCSlide_Tanz .TCS1 {
  background: url(./assets/img/kingdomlivingwebsitecontent/zanzibarbeach-v5882458-1440.jpg) center no-repeat;
  background-size: cover;
}

.TCSlide_Tanz .TCS2 {
  background: url(./assets/img/kingdomlivingwebsitecontent/zanzibarda62dc9f316b50213660d4cda15d0093-1604084318.png) center no-repeat;
  background-size: cover;
}

.TCSlide_Tanz .TCS3 {
  background: url(./assets/img/kingdomlivingwebsitecontent/zanzibarMelia-Zanzibar-Hotel-Kiwengwa-Exterior-2.JPEG) center no-repeat;
  background-size: cover;
}


@media (max-width: 785px) {
  .TCSlide {
    height: 230%;
    width: 170%;
  }

  .TCS h1 {
    font-size: 6vw;
  }

  .TCS h2 {
    font-size: 3vw;
  }

  .TCS h3 {
    font-size: 2.3vw;
    padding-left: 40vw;
    padding-right: 40vw;
  }

  .TCS a {
    font-size: 2.3vw;
  }

  .ToursCol_child {
    height: 50vw;
  }
}


.OtherTours {
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.42);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.42), rgba(0, 0, 0, 0.12));
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OtherTours h1 {
  margin: 0px;
  color: white;
  text-shadow: 0px 0px 10px white;
}

.OtherTours .row {
  width: 100%;
}

.OtherTours_deals {
  padding: 10px;
}

.OtherTours_deals_ {
  /* background-color: #04ac1b; */
  width: 100%;
  height: 20vw;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.344);
}

@media (max-width: 1400px) {
  .OtherTours_deals_ {
    height: 20vw !important;
  }
}

@media (max-width: 1200px) {
  .OtherTours_deals_ {
    height: 26vw !important;
  }
}

@media (max-width: 992px) {
  .OtherTours_deals_ {
    height: 40vw !important;
  }
}

@media (max-width: 768px) {
  .OtherTours_deals_ {
    height: 40vw !important;
  }
}

@media (max-width: 576px) {
  .OtherTours_deals_ {
    height: 80vw !important;
  }
}

@media (max-width: 498px) {
  .OtherTours_deals_ {
    height: 90vw !important;
  }
}

.OtherTours_deals_1 {
  background: url(./assets/img/Picture1.png) center no-repeat;
  background-size: cover;
}

.OtherTours_deals_2 {
  background: url(./assets/img/Picture2.png) center no-repeat;
  background-size: cover;
}

.OtherTours_deals_3 {
  background: url(./assets/img/Picture3.png) center no-repeat;
  background-size: cover;
}

.OtherTours_deals_4 {
  background: url(./assets/img/Picture4.png) center no-repeat;
  background-size: cover;
}




.BTT_container_footer {
  width: 86vw;
  /* padding-left: 7vw;
  padding-right: 7vw; */
  position: absolute;
  bottom: 0vw;
  z-index: 1;
  background-color: white;
}

.BTT_container_footer_sub {
  background-color: rgba(0, 0, 0, 0.42);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.12));
  height: 10vw;
  width: 100%;
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
}

.BCFS_Advisor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(239, 255, 244, 0.04);
  margin-right: 10px;
  width: 45vw;
  /* border-radius: 20px; */
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
  height: 8vw;
}

.BCFS_image {
  height: 8vw;
  width: 8vw;
  background: url(./assets/img/kingdomlivingwebsitecontent/P374068.jpg) center no-repeat;
  background-size: cover;
}

.BCFS_info {
  padding: 10px;
  padding-right: 20px;
}

.BCFS_info h1 {
  font-size: 1.9vw;
  color: rgb(255, 255, 255);
  margin: 0px;
}

.BCFS_info h6 {
  font-size: 1.1vw;
  color: rgba(236, 236, 236, 0.688);
  margin: 0px;
  margin-top: 0.2vw;
}

.BCFS_contact {
  height: 8vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* width: 70%; */
}


.BCFS_contact h5 {
  font-size: 1.3vw;
  font-family: 'Gill Sans';
  color: white;
  margin: 0px;
  margin-bottom: 4px;
}

.BCFS_contact p {
  font-size: 1vw;
  font-family: 'Gill Sans';
  color: white;
  letter-spacing: 1px;
}

.BCFS_contact a {
  font-size: 1vw;
  font-family: 'Gill Sans';
  color: greenyellow;
}

.BCFS_contact a:hover {
  font-size: 1vw;
  font-family: 'Gill Sans';
  color: rgb(115, 188, 5);
}



/* End of Book Travel Tours */














































































.scaleOnHover {
  transition: 0.6s;
}

.scaleOnHover:hover {
  transform: scale(1.05);
}


@font-face {
  font-family: Allura-Regular;
  src: url(./assets/fonts/Allura-Regular.ttf);
}

@font-face {
  font-family: Sketch;
  src: url(./assets/fonts/Sketch.ttf);
}

@font-face {
  font-family: Admiration_Pains;
  src: url(./assets/fonts/Admiration-Pains.ttf);
}

@font-face {
  font-family: edo;
  src: url(./assets/fonts/edo.ttf);
}

@font-face {
  font-family: The_Bully;
  src: url(./assets//fonts/The-Bully.ttf);
}

@font-face {
  font-family: Silent_Asia;
  src: url(./assets//fonts/Silent-Asia.otf);
}

@font-face {
  font-family: Pacifico_Regular;
  src: url(./assets//fonts/Pacifico-Regular.ttf);
}

@font-face {
  font-family: DancingScript_VariableFont_wght;
  src: url(./assets//fonts/DancingScript-VariableFont_wght.ttf);
}

@font-face {
  font-family: Borel_Regular;
  src: url(./assets//fonts/Borel-Regular.ttf);
}

@font-face {
  font-family: Lugrasimo_Regular;
  src: url(./assets//fonts/Lugrasimo-Regular.ttf);
}

@font-face {
  font-family: Lumanosimo_Regular;
  src: url(./assets//fonts/Lumanosimo-Regular.ttf);
}